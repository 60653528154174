:root {
	/* --header-font-size: 25.2px; */
	--header-font-size: 19.5px;
	/* --icon-size: 36px; */
	/* --icon-size: 28px; */
	/* --icon-size: 36px; */
	/* --icon-size: 48px; */
	--icon-size: 48px;
	/* --icon-size: 46px; */

	/* --gap: 12px;
	--gap: 18px; */
	/* --gap: 16px;  */
	/* --gap: 18px; */
	--gap: 20px;
	/* --gap: 8px; */

}

.reg-section-header{
	display: var(--reg-section-header-visibility);
}

.mobile-section-header{
	display: var(--mobile-section-header-visibility);
}

.section-header {
	font-size: calc(28px * 0.7);
	border-radius: 4px/4px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: flex-start;
	gap: var(--gap);

	/* width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px);  */
	width: var(--single-frame-width);
	/* width: calc(var(--single-frame-width) - 40px);  */

	display: flex;

	/* padding-top: 16px;
	padding-bottom: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-top: 18px;
	padding-bottom: 18px; */
	padding-top: 20px;
	padding-bottom: 20px;

	display: flex;

	justify-content: space-between;
	align-items: flex-start;
	flex-shrink: 0;

	/* border: 1px solid rgba(0, 0, 0, 0.25); */
	border: 1px solid var(--stroke-color);
	border: none;
	outline: 1px solid var(--stroke-color);
	/* border: 1px solid #cdcdcd; */
	/* border: 1.5px solid rgba(0, 0, 0, 0.15); */
	/* display: none; */
	

	transition:  350ms, border 0ms, outline 0ms;
	/* transition:  400ms, border 0ms, outline 0ms; */

	/* border: 1px solid rgba(0, 0, 0, 0.1);
	background-color:rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, .8); */
	/* background-color:rgba(0, 0, 0, 0.025); */

	/* width: 20px; */
	/* background-color: var(--test-color); */

}

@media (max-width: 1024px) and (orientation: portrait) {
	.section-header {
		border-radius: 0px;
		border-left: none;
		border-right: none;
		/* border: 1px solid rgba(0, 0, 0, 0.1); */
		/* width: calc(var(--single-frame-width) - 40px);  */

		/* width: 20px; */
	/* background-color: var(--test-color); */
	}
}

@media (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (orientation: portrait) {
	.section-header {
		/* font-size: 1.2rem; */
		/* font-size: 1.1rem; */
		font-size: 1rem;
		/* font-size: var(--mobile-font-size); */
	}


}

.top-row {
	display: flex;
	align-items: center;

	gap: var(--gap);
}

.section-header-title {
	font-style: normal;
	/* font-weight: 700; */
	font-weight: var(--bold-weight);
	line-height: normal;
	text-align: left;
	flex-shrink: 0;

}

.section-header-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	/* gap: 36px; */
	align-self: stretch;
	/* margin-top: 96px; */
	/* margin-bottom: 32px; */
	margin-bottom: calc( var(--vert-spacing-unit) * var(--spacing-unit-below-section-header) );
	margin-top: 96px;
	margin-top: calc( var(--vert-spacing-unit) * var(--spacing-unit-above-section-header) );
	user-select: none;
	pointer-events: none;
	
	/* text-align: left; */
	/* background-color: pink; */
}

@media (max-width: 1024px) and (orientation: portrait) {
	.section-header-wrapper {
		/* border-radius: 0px; */
		/* border-radius: 48px/48px; */
		/* border-top-right-radius: 48px;
		border-top-left-radius: 48px; */
		border-left: none;
		border-right: none;
		/* border-top: 1px rgba(0, 0, 0, 0.25) dashed; */
		/* border-bottom: 1px rgba(0, 0, 0, 0.25) solid; */
		/* border-bottom: 1px rgba(0, 0, 0, 0.25) dashed; */

		/* border-top: 1px rgba(0, 0, 0, 0.125) solid;
		border-bottom: 1px rgba(0, 0, 0, 0.125) solid; */

		border-top: 1px rgba(0, 0, 0, 0.25) solid;
		border-bottom: 1px rgba(0, 0, 0, 0.25) solid;
		/* padding-top: 38px;
		padding-bottom: 38px; */
		padding-top: 24px;
		/* padding-top: 48px; */
		padding-bottom: 32px;
		/* padding-bottom: 64px; */
		padding-left: var(--mobile-margin);
		padding-right: var(--mobile-margin);
		/* margin-top: 32px; */
		/* margin-top: 48px; */
		/* margin-bottom: 0px; */
		/* display: inline-flex; */
		/* margin-bottom: 0px; */
		/* border: 1px solid rgba(0, 0, 0, 0.1); */
		/* width: calc(var(--single-frame-width) - 40px);  */


		
		margin-left: var(--mobile-margin);
		margin-right: var(--mobile-margin);
		/* border: 1px rgba(0, 0, 0, 0.125) solid; */
		border: 1px solid var(--stroke-color);
		border-radius: 8px/8px;
	}
}

.divider-div {
	flex: 1 0 0;
}

.icon-div {
	height: var(--icon-size);
	margin-left: calc(var(--gap) * 1.5);
	/* margin-right: calc(var(--gap) * 0.05); */
}

@media (max-width: 1024px) and (orientation: portrait) {
	.icon-div {
		/* margin-left: var(--mobile-margin); */
		margin-left: 0px;
		margin-bottom: 8px;
	}
}

.icon-div img {
	object-fit: contain;
	height: 100%;
}

.section-content-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.grey-divider {
	color: rgb(231, 231, 231);
	color: hsl(0, 0%, 91%);
	color: hsl(0, 0%, 92%);
	color: var(--grey-divider-col);
}

.date-span-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	align-self: stretch;
	/* color: #ababab; */
	color: var(--sm-grey-text);
	color: var(--main-grey);
	width: var(--thumb-width);
	font-style: italic;
}

@media (max-width: 1024px) and (orientation: portrait) {
	.date-span-wrapper {
		width: auto;
		/* margin-right: var(--mobile-margin); */
		font-size: var(--mobile-font-size);
		justify-content: flex-start;
		align-items: baseline;
		align-self: auto;
		font-style: normal;
		/* flex: 1 0 0; */
	}
}

.desc-span {
	font-size: 16px;
	height: 16px;
	height: 100%;
	text-align: left;
	/* flex-shrink: 0; */
}

@media (max-width: 1024px) and (orientation: portrait) {
	:root{
		--icon-size: 36px;
	}

	.desc-span {
		font-size: var(--mobile-font-size);
		height: auto;
		/* text-align: left; */
		text-align: center;
	}
	
	.section-header-title{
		font-size: var(--mobile-font-size);
		/* text-align: left; */
		display: flex;
		flex: 1 0 0;
	}
}




/* @media (max-width: 1024px) and (orientation: portrait) {
	:root{
		--icon-size: 36px;
		--icon-size: 42px;
	}

	.desc-span {
		font-size: var(--mobile-font-size);
		height: auto;
	
	}
	
	.section-header-title{
	
		font-size: 16px;
	
		display: flex;
		flex: 1 0 0;
	}

	.date-span-wrapper{
		font-size: 14px;
	}

} */

.mobile-header-main-div{
	display: flex;
	/* gap: 16px; */
	gap: var(--mobile-margin);

align-items: center;
width: 100%;
/* background-color: beige; */
margin-right: var(--mobile-margin);
/* gap: 10px; */
}

.mobile-header-text{
	display: flex;
/* padding: 180px 226px; */
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
}



.title-and-date-row-mobile{
	display: flex;

gap: 8px;
align-items: center;


	/* right side */
/* justify-content: space-between;
align-items: center;
align-self: stretch; */



/* margin-right: var(--mobile-margin); */
/* background-color: pink; */
}


.section-header-mobile{
	display: flex;

justify-content: center;
align-items: center;
flex-direction: column;
gap: 8px;
}





@media  (max-width: 1024px) and (orientation: portrait) {


	.section-header-wrapper{
		/* margin-top: 48px; */
	}

	.carousel-information{
		/* margin-top: 64px; */
	}

	.carousel-container{
		/* padding-bottom: 64px; */
	}

	
}

@media (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (orientation: portrait) {
	.desc-span {
		/* font-size: 1.2rem; */
		margin-top: 0px;
		padding: 0px;
		font-size: var(--mobile-font-size);
		/* line-height: 1.8; */
		/* line-height: 1.4; */
		/* line-height: 1.4; */
		line-height: 1.6;
		/* line-height: 1.8; */
		/* line-height: 1.6; */
		/* color: red */
	}

	.section-header-mobile{
		gap: 6px;
		gap: var(--mobile-text-stack-gap);
		/* gap: 4px; */
		
	}

	.date-span-wrapper {
	
	/* color: blueviolet; */
	/* line-height: 1.4; */
	line-height: 1.6;
	/* line-height: 1.8; */
}

.section-header-title{
	/* line-height: 1.4; */
	line-height: 1.6;
	/* line-height: 1.8; */
	
	}

	.section-header-wrapper{
		/* margin-top: 36px; */
		/* padding: 24px; */
		/* padding: 0px; */
		/* padding: 8px; */
		/* padding: 16px; */
		/* padding: 24px; */
		/* padding-top: 32px; */
		/* padding-bottom: 32px; */
		/* background-color: red; */
		/* padding-top: 24px; */
		/* padding-bottom: 24px; */
		/* padding-top: 24px; */
		/* padding-bottom: 32px; */

	}

	.carousel-information{
		/* margin-top: 48px; */
	}

	.carousel-container{
		/* padding-bottom: 48px; */
	}
}





/* @media (min-width: 1300px)  {
	.section-header {

		width: 20px;
	background-color: var(--test-color);
	}
} */