:root {
	--inactive-transition-speed: 350ms ease;
	--active-transition-speed: 0ms;
	--active-bg: rgba(0, 0, 0, 0.4);
	--active-bg-mouseover: rgba(0, 0, 0, 0.45);
	--active-bg-clicked: rgba(0, 0, 0, 0.55);
	--indicator-dot-stroke-width: 1px;
	--active-bg: rgba(0, 0, 0, 0.45);
	--active-bg-mouseover: rgba(0, 0, 0, 0.5);
	--active-bg-clicked: rgba(0, 0, 0, 0.6);
	--active-bg: rgba(0, 0, 0, 0.45);
	--active-bg-mouseover: rgba(0, 0, 0, 0.55);
	--active-bg-clicked: rgba(0, 0, 0, 0.65);

	--active-bg: rgba(0, 0, 0, 0.5);
	--active-bg-mouseover: rgba(0, 0, 0, 0.4);
	--active-bg-clicked: rgba(0, 0, 0, 0.5);

	--active-bg: rgba(0, 0, 0, 0.475);
	--active-bg-mouseover: rgba(0, 0, 0, 0.375);
	--active-bg-clicked: rgba(0, 0, 0, 0.475);
	--active-bg: hsl(0, 0%, 50%);
	--active-bg-mouseover: hsl(0, 0%, 60%);
	--active-bg-clicked: hsl(0, 0%, 50%);


	--activity-blue: #c6e3f7;
	--mobile-object-grey: #cdcdcd;
	--mobile-object-dark: black;
	--mobile-object-dark: var(--sm-grey-text);
	--mobile-object-dark: #9c9c9c;
	--mobile-object-dark: hsl(0, 0%, 60%);
	--mobile-object-grey: hsl(0, 0%, 80%);
	--mobile-arrow-color: var(--mobile-object-dark);
	--carousel-activity-outline-color: hsl(0, 0%, 92%);

	/* --arrow-container-transition: 350ms ease; */
	--arrow-container-transition: 500ms ease;
	--arrow-container-transition: 1500ms ease;
	--arrow-container-transition-out: 250ms ease;


	--arrow-container-transition: 250ms ease;
	--arrow-container-transition-out: 1000ms ease;

	--arrow-container-transition: 100ms ease;
	/* --arrow-container-transition: 0ms ease; */
	--arrow-container-transition-out: 500ms ease;

	/* --arrow-container-transition: 500ms ease; */
	--arrow-container-transition-out: 500ms ease;


	/* --arrow-container-transition: 200ms ease; */
	/* --arrow-container-transition-out: 200ms ease; */


	--arrow-container-transition: 100ms ease;
	/* --arrow-container-transition-out: 100ms ease; */


	/* --arrow-container-transition: 1500ms ease; */
	/* --arrow-container-transition-out: 1500ms ease; */


		/* --arrow-container-transition: 300ms ease; */
	/* --arrow-container-transition-out: 500ms ease; */

	/* --arrow-container-transition: 100ms ease; */
	/* --arrow-container-transition-out: 350ms ease; */


	/* --arrow-container-transition: 300ms ease; */
	/* --arrow-container-transition-out: 300ms ease; */

	/* --arrow-container-transition: 500ms ease; */
	/* --arrow-container-transition-out: 500ms ease; */
	
	--arrow-container-transition: 300ms ease;
	--arrow-container-transition-out: 300ms ease;

	/* neither here nor there */
	/* --arrow-container-transition: 200ms ease; */
	/* --arrow-container-transition-out: 200ms ease; */

	/* --arrow-container-transition: 100ms ease; */
	/* --arrow-container-transition-out: 100ms ease; */
	
	/* --arrow-container-transition: 150ms ease; */
	/* --arrow-container-transition-out: 150ms ease; */

	/* --arrow-container-transition: 0ms ease; */
	/* --arrow-container-transition-out: 0ms ease; */
	
	/* --arrow-container-transition: 75ms ease; */
	/* --arrow-container-transition-out: 250ms ease; */


	/* --arrow-container-transition: 75ms ease; */
	/* --arrow-container-transition-out: 75ms ease; */

	/* --arrow-container-transition: 100ms ease; */
	/* --arrow-container-transition-out: 250ms ease; */


	/* --arrow-container-transition: 150ms ease; */
	/* --arrow-container-transition-out: 250ms ease; */
	/* --arrow-container-transition-out: 450ms ease; */


	/* --arrow-container-transition: 250ms ease; */
	/* --arrow-container-transition-out: 250ms ease; */
	
	/* --arrow-container-transition: 500ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* --arrow-container-transition-out: 500ms cubic-bezier(0.19, 1, 0.22, 1);  */
	
	/* --arrow-container-transition: 525ms cubic-bezier(.58,0,.35,.99); */
	/* --arrow-container-transition-out: 525ms cubic-bezier(.58,0,.35,.99);  */
	
	/* --arrow-container-transition: 525ms cubic-bezier(.65,.01,.35,1); */
	/* --arrow-container-transition-out: 525ms cubic-bezier(.65,.01,.35,1);  */


	/* --arrow-container-transition-out: 2ms ease; */
}

.italic {
	font-style: italic;
}

.carousel-and-arrows {
	display: flex;
	width: auto;
	padding: auto;
	justify-content: center;
	align-items: center;
}

.arrow-container {
	display: inline-flex;

	flex-shrink: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 96px;
	height: 100px;

	border-radius: 4px;
	outline: var(--active-bg) 1px solid;
	background: var(--active-bg);
	/* transition: background var(--inactive-transition-speed); */
	outline: 1px solid #d4d4d4;
	background: white;
	/* transition: background var(--inactive-transition-speed), translate 100ms, scale 100ms; */
	display: inline-flex;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 96px;
	height: 100px;
	width: 45px;
	height: 96px;
	border-radius: 4px;
	outline: hsl(0, 0%, 92%) 1px solid;
	background: var(--active-bg);
	/* transition: background var(--inactive-transition-speed), translate 500ms; */

}

.arrow-container:hover {

	--click-anim: 0ms;
	/* transition: background var(--click-anim), outline var(--click-anim); */

	/* transition: translate 100ms; */
	/* transition: background var(--click-anim), outline var(--click-anim), translate 100ms, scale 100ms ease; */
	--click-anim: 100ms;
	/* transition: background var(--click-anim), outline var(--click-anim); */

}

.arrow-container:active {
	/* transition: background 0ms, outline 0ms, translate 100ms; */
	background-color: rgba(0, 0, 0, 0.01);
	background-color: rgb(249, 248, 248);
	scale: 100%;
	outline: 1px solid #d4d4d4;
	/* transition: background 0ms, outline 0ms, translate 50ms; */
	background: var(--active-bg-clicked);
	outline: var(--active-bg-clicked) 1px solid;
}

.arrow-container-moving {
	/* transition: background var(--inactive-transition-speed), outline var(--inactive-transition-speed), top 200ms ease-out, translate 100ms, scale 100ms; */
	/* transition: background var(--inactive-transition-speed), outline var(--inactive-transition-speed), top 200ms ease-out, ; */
	/* background-color: red; */
	/* transition: top 200ms ease-out; */
	/* transition: top 250ms ease-out; */
	/* transition: top 300ms ease-out; */

}

.inactive-arrow-container {
	outline: var(--stroke-color) 1px solid;
	background: none;
}



.arrow-container2 {
	display: inline-flex;
	margin-top: 24px;
	margin-bottom: 24px;
	flex-shrink: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 64px;
	align-self: stretch;
	border-radius: 4px;
	background: rgba(0, 0, 0, 0.05);
}

.arrow-container-container {
	display: inline-flex;
	margin-top: 64px;
	margin-bottom: 64px;

	flex-shrink: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 96px;
	align-self: stretch;
	border-radius: 4px;
}

@media screen and (max-width: 1280px) {
	.arrow-container-container {
		display: none;
	}
}

.return {
	/* transition: all 100ms ease-in-out; */
}

.left-side {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 1024px) {
	.left-side {
		gap: 0px;
	}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.left-side {
		width: var(--frame-width);
	}
}

.carousel-information {
	user-select: none;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;
	padding-left: 96px;

	margin-top: 72px;
	margin-top: 84px;
	margin-bottom: 24px;
	margin-bottom: var(--vert-spacing-unit);
	margin-top: 0px;

	text-align: left;
}

.mobile-theme-div {
	user-select: none;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 8px;

	text-align: left;
}

.mobile-info-divider {
	display: none;
}

.mobile-content {
	display: none;
}

.desktop-content {
	display: initial;
}

@media screen and (max-width: 1024px) {
	.mobile-content {
		display: initial;
	}

	.desktop-content {
		display: none;
	}

	.mobile-theme-div {
		user-select: none;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 8px;
		gap: 4px;
		gap: 6px;
		gap: var(--mobile-text-stack-gap);
		text-align: left;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.mobile-info-divider {
		display: inline;
		color: #e7e7e7;
		margin-left: var(--mobile-text-stack-gap);
		margin-right: var(--mobile-text-stack-gap);
	}

	.carousel-category {
		color: var(--sm-grey-text);
	}

	.carousel-title {}

	.carousel-desc {}

	.slide-information {}

	.mobile-carousel-information {
		line-height: 1.8;
		display: none;
	}

	.mobile-carousel-title {

		font-weight: var(--bold-weight);
	}

	.mobile-carousel-category {}

	.mobile-carousel-desc {}
}

.landscape-mobile-info-divider {
	display: none;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.slide-information {
		font-size: 8px;
		margin-top: 12px;
		margin-top: 8px;
	}
}

@media screen and (max-width: 768px) and (orientation: portrait) {
	.slide-information {}
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
	.carousel-information {

		width: calc(var(--frame-width) - 48px);
		display: flex;
		align-self: center;
		font-size: var(--mobile-font-size);
		margin-bottom: calc(var(--vert-spacing-unit) - 8px);
	}
}

@media screen and (max-width: 1280px) {
	.carousel-information {
		padding-left: 0px;
	}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.carousel-information {
		padding-left: 0px;

		display: flex;
		width: calc(95vw - var(--thumb-width));
	}

	.landscape-mobile-info-divider {
		display: inline;
		color: #e7e7e7;
	}
}

.carousel-title {
	font-weight: var(--bold-weight);
}

.carousel-category {}

.carousel-desc {}

.slide-information {

	justify-content: center;
	user-select: none;
	line-height: 1.8;
}


.slide-indicator {
	border-radius: 4.9px;
	background: var(--active-bg);
	padding: 2px 10px 2px 10px;
	/* height: 50px;
	width: 150px; */
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	/* align-self: stretch;
	justify-content: center; */
}

.indicator-container-html {
	border-radius: 4px/4px;
	background: var(--active-bg);
	outline: var(--active-bg) 1px solid;
	padding: 0px 10px 0px 10px;
	padding: 0px 12px 0px 12px;
	padding: 0px 11px 0px 11px;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	gap: 11px;
	gap: 10px;
	/* transition: background-color var(--inactive-transition-speed), outline var(--inactive-transition-speed); */

	user-select: none;
}

.inactive-indicator {
	background: none;
	outline: var(--stroke-color) 1px solid;

}

.indicator-dot-html {

	height: 8px;
	width: 8px;

	border-radius: 100%;

	border: var(--indicator-dot-stroke-width) white solid;
	margin: 6px 0px 6px 0px;
	/* transition: border-color 0ms; */
}

.inactive-hover-indicator-dot {
	border: var(--stroke-color) var(--indicator-dot-stroke-width) solid;
}

.active-hover-indicator-dot {
	border: var(--indicator-dot-stroke-width) red solid;
}

.active-indicator-dot {
	border: var(--indicator-dot-stroke-width) solid var(--activity-blue);
	background-color: var(--activity-blue);
}

.inactive-hover-active-indicator-dot {
	border: var(--indicator-dot-stroke-width) solid var(--activity-blue);
}

.indicator-dot-border-transition {
	/* transition: border-color var(--inactive-transition-speed); */
}

@media screen and (max-width: 1024px) {
	.indicator-container-html {
		border-radius: 3px/3px;
		background: var(--active-bg);
		outline: var(--active-bg) 1px solid;
		padding: 0px 7.5px 0px 7.5px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 7.5px;
		/* transition: background-color var(--inactive-transition-speed), outline var(--inactive-transition-speed); */
	}

	.inactive-indicator {
		background: none;
		outline: var(--stroke-color) 1px solid;
	}

	.indicator-dot-html {
		border-radius: 100%;

		height: 6px;
		width: 6px;
		border: var(--indicator-dot-stroke-width) white solid;
		margin: 4.5px 0px 4.5px 0px;
		/* transition: border-color 0ms; */
	}

	.inactive-hover-indicator-dot {
		border: #cdcdcd var(--indicator-dot-stroke-width) solid;
	}

	.active-hover-indicator-dot {
		border: var(--indicator-dot-stroke-width) red solid;
	}

	.active-indicator-dot {
		border: var(--indicator-dot-stroke-width) solid var(--activity-blue);
		background-color: var(--activity-blue);
	}

	.inactive-hover-active-indicator-dot {
		border: var(--indicator-dot-stroke-width) solid var(--activity-blue);
	}

	.indicator-dot-border-transition {
		/* transition: border-color var(--inactive-transition-speed); */
	}
}

.sandwich {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pillars {
	display: inline-flex;
	align-items: flex-start;
	gap: 4vw;
	gap: var(--pillar-gap);
}

.right-side {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-self: stretch;
	/* flex-direction: column;
	align-items: flex-start; */
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	.right-side {
		display: none;
	}
}


.page-indicator {
	user-select: none;
	display: flex;
	align-self: stretch;
	justify-content: flex-start;
	justify-content: center;
	margin-bottom: 39.5px;
	width: var(--thumb-width);
}

.arrows path {
	fill: white;
	fill: var(--main-grey);
	fill: white;
	fill: var(--mobile-object-dark);
}

.arrows {
	stroke: white;
	stroke: var(--main-grey);
	stroke: white;
	stroke-width: 1;
	stroke-width: 0;

	/* transition: stroke var(--inactive-transition-speed), filter var(--inactive-transition-speed), transform var(--inactive-transition-speed), stroke-width var(--inactive-transition-speed), fill-opacity var(--inactive-transition-speed); */
}

.terminal-arrows path {
	fill: rgba(0, 0, 0, 0.15);
	stroke: rgba(0, 0, 0, 0.15);
	fill: #d9d9d9;
	stroke: #d9d9d9;
}

.terminal-arrows rect {
	fill: rgba(255, 255, 255, 0.5);
}

.terminal-arrows {
	filter: none;
}

.inactive-arrows {

	stroke: var(--stroke-color);
	stroke-width: 1;

	fill: var(--mobile-object-grey);
	stroke: var(--mobile-object-grey);
}

.inactive-arrows path {}

.arrows2 {}

.right-arrow-inactive {
	stroke-width: 2px;
	stroke: #cdcdcd;
}

.tomato {
	align-self: stretch;
}

.carousel-container {
	padding-top: calc(var(--vert-spacing-unit) * var(--spacing-unit-above-carousel));
	padding-bottom: calc(var(--vert-spacing-unit) * var(--spacing-unit-below-carousel));
}

.first-carousel-container {
	padding-top: calc(var(--vert-spacing-unit) * var(--spacing-unit-above-first-carousel));
}

.last-carousel-container {
	padding-bottom: calc(var(--vert-spacing-unit) * var(--spacing-unit-below-last-carousel));
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
	.carousel-container {}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.carousel-container {
		width: var(--single-frame-width);
		margin: auto;
	}
}


.figma-instructions {
	font-style: italic;

	color: var(--sm-grey-text);
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
	.figma-instructions {
		margin-top: 0px;
	}
}

.slide-desc-container {
	display: flex;
	align-items: flex-start;
	flex-shrink: 0;
	background-color: #d7ddda;
}

.left-mobile-arrow {
	display: none;
}

.right-mobile-arrow {
	display: none;
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
	.left-mobile-arrow {
		display: flex;
		width: 6vw;
		padding-right: 3vw;
		padding-right: 4vw;
		color: #cdcdcd;
		color: var(--mobile-arrow-color);
		font-size: var(--mobile-arrow-font-size);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		align-self: stretch;
	}

	.right-mobile-arrow {
		display: flex;
		width: 6vw;
		padding-left: 3vw;
		padding-left: 4vw;
		color: #cdcdcd;
		color: var(--mobile-arrow-color);
		justify-content: flex-end;
		font-size: var(--mobile-arrow-font-size);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		align-self: stretch;
	}

	.slide-information {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: var(--mobile-font-size);
	}
}

.alt-mobile-desc-main-div {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	gap: 16px;
}

.alt-mobile-desc-numeric-position {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	color: var(--sm-grey-text);
}

.alt-mobile-desc-divider-div {
	display: flex;
	width: 1px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	background-color: #e7e7e7;
}

.alt-mobile-render-text {
	text-align: left;
}

@media screen and (min-width: 551024px) and (orientation: portrait) {
	.indicator-container-html {
		outline: none;
		gap: 12px;
		background: none;
	}

	.inactive-indicator {}

	.indicator-dot-html {
		border-radius: 8px;
		height: 6px;
		width: 6px;

		border: var(--indicator-dot-stroke-width) rgb(168, 168, 168) solid;
		margin: 4.5px 0px 4.5px 0px;
		/* transition: border-color 0ms; */
	}

	.inactive-hover-indicator-dot {
		/* transition: background-color 75ms; */
	}

	.inactive-hover-indicator-dot {}

	.active-indicator-dot {

		background-color: rgb(168, 168, 168);
		border: var(--indicator-dot-stroke-width) rgb(168, 168, 168) solid;
		/* transition: background-color 75ms; */
	}
}

.indicator-dot-html {
	border-radius: 100%;

	margin: 0px;
	background-color: hsl(0, 0%, 92%);
	border: var(--stroke-color) 1px solid;
	background-color: white;
}

.indicator-container-html {
	background: none;
	outline: var(--stroke-color) 1px solid;
	padding: 7px 12px 7px 12px;
	border-radius: 8px;

	scale: 0.9;
	gap: 10px;
}

.inactive-hover-indicator-dot {
	background-color: #e7e7e7;
	border: #e7e7e7 1px solid;
	border: var(--sm-grey-text) 1px solid;
	border: var(--mobile-object-grey) 1px solid;
	border: #cdcdcd 2px solid;
	border: #cdcdcd 1px solid;
	border: #bebebe 1px solid;
	border: #9c9c9c 1px solid;
	border: var(--sm-grey-text) 1px solid;
	background-color: white;
	background-color: hsl(0, 0%, 92%);
	border: var(--sm-grey-text) 1px solid;
	border: var(--mobile-object-grey) 1px solid;
}

.active-indicator-dot {
	border: var(--activity-blue) 1px solid;
	background-color: var(--activity-blue);
	border: rgb(134, 134, 134) 1px solid;
	border: var(--sm-grey-text) 1px solid;
	background-color: var(--sm-grey-text);
	border: var(--mobile-object-grey) 2px solid;
	border: var(--sm-grey-text) 1px solid;
	background-color: var(--sm-grey-text);
	border: var(--mobile-object-dark) 1px solid;
	background-color: var(--mobile-object-dark);

	border: var(--activity-blue) 1px solid;
	background-color: var(--activity-blue);
}

.inactive-hover-indicator-dot {
	background-color: white;
}

.inactive-hover-active-indicator-dot {
	background-color: var(--mobile-object-grey);
	border: var(--mobile-object-grey) 1px solid;
}

.arrow-container {
	background-color: hsl(0, 0%, 98%);
	outline: var(--stroke-color) 1px solid;
	user-select: none;
	background-color: hsl(0, 0%, 92%);
	background-color: white;
	/* transition: all 1500ms; */
}

.inactive-arrow-container {
	background-color: white;
	outline: var(--mobile-object-grey) solid 1px;
	filter: none;
	box-shadow: none;
	outline: var(--stroke-color) solid 1px;
	/* transition: all 1500ms; */
}

.arrow-container:hover {}

.arrow-container:active {
	/* transition: background 0ms, outline 0ms, translate 100ms; */
	background-color: #fdfdfd;
	outline: #d4d4d4 solid 1px;
}

.arrows path {
	stroke-opacity: 0;
	fill: var(--main-grey);
}

.inactive-arrows path {}

.arrow-container path {
	fill: red;
	fill: var(--main-grey);
	fill: white;
}

.arrow-container:hover {}

.arrow-container:active {
	background-color: var(--stroke-color);
	background-color: var(--mobile-object-grey);
	background-color: var(--stroke-color);
	background-color: hsl(0, 0%, 65%);
	background-color: hsl(0, 0%, 45%);
	/* transition: background-color 100ms; */
}

.arrow-container:hover path {}

.arrow-container:active path {
	fill: blue;
	fill: white;
	fill: var(--mobile-object-dark);
	fill: hsl(0, 0%, 85%);
	fill: hsl(0, 0%, 65%);
	/* transition: fill 100ms; */
	fill: hsl(0, 0%, 80%);
	fill: hsl(0, 0%, 85%);
}

.inactive-arrows path {
	stroke-width: 0;
	fill: var(--mobile-object-grey);
	stroke-width: 1;
	fill-opacity: 0;
	stroke: var(--mobile-object-grey);
	stroke-opacity: 1;
	stroke: var(--stroke-color);
}

.arrow-container:hover {
	--click-anim: 100ms;
	/* transition: background var(--click-anim), outline var(--click-anim); */
	/* transition: translate 100ms; */
	/* transition: background var(--click-anim), outline 0ms, translate 100ms, scale 100ms ease; */
}

.arrow-container:active {
	/* transition: background 0ms, outline 0ms, translate 100ms; */
	background-color: rgba(0, 0, 0, 0.01);
	scale: 100%;
	outline: 1px solid #d4d4d4;
	box-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0);
	background-color: hsl(0, 0%, 50%);
	/* background-color: hsl(0, 0%, 63%); */
	background-color: hsl(0, 0%, 62%);
	background-color: hsl(0, 0%, 63%);
}

.arrow-container {
	background-color: #808080;
	background-color: var(--main-grey);
	/* transition: all 175ms; */
}

.arrow-container path {
	/* transition: all 175ms; */
}



.arrows {
	filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .25));
	/* transition: filter 3000ms; */
	transition: filter var(--arrow-container-transition);
}

.inactive-arrows {
	filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, .0));
	/* filter: drop-shadow(0px 0px 0px rgb(255, 0, 0)); */
}

.arrow-container path {
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .25)); */

}

.inactive-arrow-container {
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .25)); */
}

.inactive-arrow-container {
	background-color: white;
	/* transition: all 175ms; */
}

.arrow-container:hover {
	/* transition: all 1ms; */
}

.arrow-container:active {}

@media screen and (max-width: 1024px) {
	.indicator-dot-html {
		border-radius: 100%;
		height: 6px;
		width: 6px;

		margin: 0px;
	}

	.indicator-container-html {
		background: none;
		outline: var(--stroke-color) 1px solid;
		outline: none;
		outline: var(--mobile-object-grey) 1px solid;
		padding: 6px 8px 6px 8px;
		border-radius: 8px/8px;

		scale: 0.9;
	}

	.inactive-hover-indicator-dot {
		background-color: #e7e7e7;
		border: #e7e7e7 1px solid;
		border: var(--sm-grey-text) 1px solid;
		border: var(--mobile-object-grey) 1px solid;
		border: #cdcdcd 2px solid;
		border: #cdcdcd 1px solid;
		border: #bebebe 1px solid;
		border: #9c9c9c 1px solid;
		border: var(--sm-grey-text) 1px solid;
		background-color: white;
		background-color: hsl(0, 0%, 92%);
		border: var(--sm-grey-text) 1px solid;
		border: var(--mobile-object-grey) 1px solid;
	}

	.active-indicator-dot {
		border: var(--activity-blue) 1px solid;
		background-color: var(--activity-blue);
		border: rgb(134, 134, 134) 1px solid;
		border: var(--sm-grey-text) 1px solid;
		background-color: var(--sm-grey-text);
		border: var(--mobile-object-grey) 2px solid;
		border: var(--sm-grey-text) 1px solid;
		background-color: var(--sm-grey-text);
		border: var(--mobile-object-dark) 1px solid;
		background-color: var(--mobile-object-dark);

	}

	.indicator-dot-html {}
}

@media screen and (min-width: 1024px) and (orientation: portrait), screen and (min-width: 1600px) and (orientation: landscape) {
	.slide-information {}

	.slide-information:hover {}

	.slide-information:active {}

	.slide-information-single {
		cursor: auto;
		background-color: none;
	}

	.slide-information-single:hover {
		cursor: auto;
		background-color: white;
	}

	.slide-information-single:active {
		cursor: auto;
		background-color: none;
	}
}

.slide-indicator-div {
	display: none;
}

@media (max-width: 1024px) and (orientation: portrait) {
	.slide-indicator-div {
		display: unset;
	}
}

.carousel-container {}

.carousel-divider {
	margin-top: 24px;
	margin-top: 32px;
	width: calc(var(--frame-width) + (var(--mat-margin) * 2));
	border-top: 1px var(--stroke-color) solid;
}

.carousel-information-p {
	margin-top: var(--vert-spacing-unit);
	margin-bottom: var(--vert-spacing-unit);
	margin-top: calc(var(--vert-spacing-unit) - 8px);
	margin-bottom: calc(var(--vert-spacing-unit) - 8px);
}

@media (max-width: 1024px) and (orientation: landscape), (max-width: 768px) and (orientation: portrait) {
	.carousel-information-p {}
}





.arrow-container {
	/* transition: all 1000ms, top 0ms; */
	/* transition: all 350ms, top 0ms; */
	/* transition: all 350ms, top 0ms; */
	transition: all var(--arrow-container-transition), top 0ms;
}

.arrow-container path {
	/* transition: all 1000ms, top 0ms; */
	/* transition: all 350ms, top 0ms; */
	transition: all var(--arrow-container-transition), top 0ms;
	/* transition: filter 3050ms, top 0ms; */
	/* transition: filter 7s cubic-bezier(0.42, 0, 0.58, 1); */
}


.arrow-container-moving {
	/* transition: background var(--inactive-transition-speed), outline var(--inactive-transition-speed), top 200ms ease-out, translate 100ms, scale 100ms; */
	/* transition: background var(--inactive-transition-speed), outline var(--inactive-transition-speed), top 200ms ease-out, ; */
	/* background-color: red; */
	/* background-color: hsl(0, 0%, 56%); */
	transition: top 200ms ease-out;
	/* transition: top 200ms ease-out, background 500ms; */
	transition: top 225ms ease-out;
	transition: all var(--arrow-container-transition), top 225ms ease-out;
	transition: all var(--arrow-container-transition), top 225ms ease-in;
	transition: all var(--arrow-container-transition), top 200ms ease-in;
	/* transition: all var(--arrow-container-transition), top 225ms; */
	/* transition: top 250ms ease-out; */
	/* transition: top 300ms ease-out; */

}





/* alt try */


.arrow-container, .arrow-container path {

	transition: all var(--arrow-container-transition), top 0ms;
}



.arrow-container-moving {
	transition: all var(--arrow-container-transition), top 225ms ease-in;
	transition: all var(--arrow-container-transition), top 200ms ease-in;
}


.inactive-arrow-container, .inactive-arrow-container path, .inactive-arrows {

	transition: all var(--arrow-container-transition-out), top 0ms;

}


/* instant maker */

.arrow-container:hover, .arrow-container:hover path, .inactive-arrow-container:hover, .inactive-arrow-container:hover path {
	transition: none;
}
.arrow-container:hover div {
	transition: none;
}

/* instant maker */

.arrow-container:active *{
	filter: none;
}



.arrow-container:hover{
	background-color: hsl(0, 0%, 60%);
}

.arrow-container:active path {
	fill: hsl(0, 0%, 80%);
	fill: hsl(0, 0%, 85%);
}

.arrow-container:active{
	background-color: hsl(0, 0%, 56%);
	background-color: hsl(0, 0%, 60%);
}