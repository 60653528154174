body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  /* --font-inter: Inter;
  --font-inherit: inherit; */


  /* font sizes */
  /* --font-size-8xs: 0.31rem;
  --font-size-3xs: 0.63rem;
  --font-size-base: 1rem;
  --font-size-5xs: 0.5rem;
  --font-size-lgi-8: 1.24rem;
  --font-size-8xl-2: 1.7rem; */
/*   
  --font-size-8xs: 1rem;
  --font-size-3xs: 1rem;
  --font-size-base: 1rem;
  --font-size-5xs: 1rem;
  --font-size-lgi-8: 1rem;
  --font-size-8xl-2: 1rem;
 
  --font-size-8xs: .9em;
  --font-size-3xs: .9em;
  --font-size-base: .9em;
  --font-size-5xs: .9em;
  --font-size-lgi-8: .9em;
  --font-size-8xl-2: .9em;
  */
  /* --font-size-8xs: 12px;
  --font-size-3xs: 12px;
  --font-size-base: 12px;
  --font-size-5xs: 12px;
  --font-size-lgi-8: 12px;
  --font-size-8xl-2: 12px; */

  /* Colors */
  --color-lightskyblue: #75cdff;
  --color-black: #000;
  --color-yellow: #fff740;
  --color-lavenderblush: #ffefef;
  --color-white: #fff;
  --color-sandybrown: #ffc075;
  --color-lightcoral: #ff9393;
  --color-gainsboro-100: #e7e7e7;
  /* --color-gainsboro-100: #e6e6e6; */
  /* --color-gainsboro-100: #cdcdcd; */
  --color-gainsboro-200: #d9d9d9;

  /* Gaps */
  --gap-9xl-8: 1.8rem;
  --gap-smi-8: 0.8rem;
  --gap-22xl-6: 2.6rem;
  --gap-19xl-4: 2.4rem;

  /* Paddings */
  --padding-smi: 0.81rem;
  --padding-2xl: 1.31rem;

  /* Border radiuses */
  --br-11xs: 2px;
  --br-xs-2: 11.2px;
  --br-9xs-2: 3.2px;
  --br-3xs-6: 9.6px;
  --br-8xl-2: 27.2px;


  /* --line-color: #FF9393; */
  --line-color: #e7e7e7;
}










.letter-133-child {
  position: absolute;
  top: 2114.2px;
  left: 280.47px;
  width: 416.22px;
  height: 94.43px;
  object-fit: cover;
  display: none;
}
.image-56-icon1,
.image-70-icon {
  position: absolute;
  top: 0;
  left: 0.72px;
  width: 113.5px;
  height: 25.56px;
  object-fit: cover;
}
.image-56-icon1 {
  top: 231.53px;
  left: 0;
  width: 112.03px;
  height: 41.61px;
}
.image-70-parent {
  position: absolute;
  top: 668.31px;
  left: 127.51px;
  width: 114.22px;
  height: 273.14px;
  display: none;
}
.stack-skills-child {
  position: absolute;
  top: 6.4px;
  left: 194.04px;
  width: 416.22px;
  height: 94.43px;
  object-fit: cover;
}
.frame-by-frame {
  position: relative;
}
.frame-by-frame-animation-wrapper {
  position: absolute;
  top: 92.83px;
  left: 161.65px;
  border-radius: var(--br-3xs-6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.compositing-parent,
.d-modeling-parent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.compositing-parent {
  left: 226.46px;
  align-items: center;
  justify-content: center;
}
.animation {
  position: relative;
  display: flex;
  align-items: center;
  width: 75.22px;
}
.animation-parent,
.ux-design-parent {
  position: absolute;
  top: 0;
  left: 441.72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.ux-design-parent {
  left: 625.78px;
  align-items: flex-end;
}
.border,
.stack-skills {
  position: absolute;
  top: 2107.79px;
  left: 86.42px;
  width: 808.23px;
  height: 114.62px;
  display: none;
}
.border {
  top: 0;
  left: 0;
  border: 6.4px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 979.48px;
  height: 3053.66px;
}
.group-child,
.group-child1,
.group-inner,
.group-item,
.rectangle-div {
  position: absolute;
  top: 0;
  left: 15.85px;
  border-radius: 27.21px;
  border-top: 1.6px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  width: 354.3px;
  height: 216.98px;
}
.group-child1,
.group-inner,
.group-item,
.rectangle-div {
  left: 386.37px;
  width: 490.96px;
}
.group-child1,
.group-inner,
.rectangle-div {
  top: 216.98px;
  left: 0;
  width: 234.1px;
}
.group-child1,
.rectangle-div {
  left: 253.1px;
}
.group-child1 {
  left: 519.51px;
  width: 371.56px;
}
.rectangle-group {
  position: absolute;
  top: 2439.35px;
  left: 51.52px;
  width: 891.07px;
  height: 433.96px;
  display: none;
}
.span {
  color: var(--color-gainsboro-100);
}
.michaelglovingmailcom {
  line-height: 136.02%;
}
.portfolio {
  position: relative;
  color: #1c9be3;
  display: none;
}
.michael-glovin-parent {
  width: 806.63px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.frame-child {
  position: relative;
  width: 985.95px;
  height: 1.6px;
  /* height: 2px; */
  object-fit: cover;
  background-color: var(--line-color);
}
.master-of-science {
  /* font-family: var(--font-inter); */
}
.master-of-science-container {
  position: relative;
  display: inline-block;
  width: 710.6px;
  flex-shrink: 0;
}
.image-57-icon {
  position: relative;
  width: 51.21px;
  height: 52.64px;
  object-fit: cover;
}
.frame-item {
  position: relative;
  background-color: var(--color-gainsboro-200);
  width: 0;
  height: 9.6px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.master-of-science-integrated-d-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.image-73-icon {
  position: relative;
  width: 51.21px;
  height: 50.89px;
  object-fit: cover;
}
.edu-contents,
.edu-frame,
.exp-content-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.edu-contents {
  align-self: stretch;
  align-items: flex-start;
}
.edu-frame,
.exp-content-frame {
  align-items: center;
}
.edu-frame {
  width: 806.63px;
}
.exp-content-frame {
  align-self: stretch;
}
.book-publisher {
  color: var(--color-black);
}
.routledgefocal-press-book-p {
  margin: 0;
}
.routledgefocal-press-container {
  line-height: 136.02%;
}
.reach-global-container,
.routledgefocal-press-container {
  align-self: stretch;
  position: relative;
}
.sketching-parent {
  gap: 41.61px;
}
.sketching-parent,
.skill-contents-temp-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.skill-contents-temp {
  position: absolute;
  top: 0;
  left: 0;
  width: 749.01px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.skill-contents-temp-wrapper,
.vector-icon {
  position: relative;
  width: 0;
  height: 0;
}
.vector-icon {
  width: 124.17px;
  height: 1.6px;
  object-fit: cover;
  background-color: var(--line-color);

}
.art {
  position: relative;
  line-height: 136.02%;
}
.frame-child1 {
  position: relative;
  width: 125.73px;
  height: 1.6px;
  background-color: var(--line-color);
  object-fit: cover;
}
.frame-container,
.vector-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.vector-parent {
  align-items: center;
  gap: var(--gap-smi-8);
}
.frame-container {
  padding: 0 40.01127243041992px;
  align-items: flex-start;
  gap: 99.23px;
  text-align: center;
}
.d-modeling2 {
  margin: 0;
  padding-left: var(--padding-2xl);
}
.d-modeling-group,
.graphic-design-parent {
  width: 150.44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28.81px;
}
.graphic-design-parent {
  height: 108.83px;
}
.component-9-parent,
.frame-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.frame-parent1 {
  flex: 1;
  justify-content: space-between;
  background-color: #ff9393;
}
.component-9-parent {
  width: 805.03px;
  justify-content: flex-start;
  /* font-size: var(--font-inherit); */
  /* font-family: var(--font-inherit); */
  background-color: #1c9be3;
  
  
}
.skill-temp-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.d,
.d1 {
  position: absolute;
  top: 0;
  left: 138.53px;
  line-height: 136.02%;
}
.d1 {
  top: 0.25px;
  left: 576.55px;
  display: inline-block;
  width: 31.12px;
}
.group-child2,
.group-child3,
.group-child4,
.group-child5 {
  position: absolute;
  top: 10.65px;
  left: 0;
  width: 124.17px;
  height: 1.6px;
  background-color: var(--line-color);
  object-fit: cover;
}
.group-child3,
.group-child4,
.group-child5 {
  left: 172.5px;
  width: 125.73px;
}
.group-child4,
.group-child5 {
  left: 372.62px;
  width: 189.79px;
}
.group-child5 {
  top: 11.42px;
  left: 621.2px;
  width: 187.25px;
}
.d-parent,
.image-21-icon {
  position: relative;
  width: 808.45px;
  height: 22.25px;
}
.image-21-icon {
  width: 48.23px;
  height: 51.21px;
  object-fit: cover;
}
.zbrush-expert {
  position: relative;
  line-height: 136.02%;
  display: inline-block;
  width: 60.82px;
}
.image-21-parent {
  border-radius: var(--br-xs-2);
  width: 51.21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-22-icon {
  position: relative;
  width: 51.21px;
  height: 51.21px;
  object-fit: cover;
}
.blender {
  position: relative;
  line-height: 136.02%;
  display: inline-block;
  width: 59.22px;
}
.image-23-icon,
.image-25-icon {
  position: relative;
  width: 51.21px;
  height: 51.21px;
  object-fit: cover;
}
.image-25-icon {
  border-radius: 6.43px;
}
.image-23-icon {
  border-radius: var(--br-3xs-6);
}
.illustrator,
.photoshop-expert {
  position: relative;
  line-height: 136.02%;
  display: inline-block;
  width: 81.62px;
}
.illustrator {
  width: 72.02px;
}
.software-contents-temp {
  width: 793.82px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.d-animation,
.languages-working-knowledge,
.video-editing {
  position: absolute;
  top: 0;
  left: 32.13px;
  line-height: 136.02%;
  display: inline-block;
  width: 99.85px;
}
.languages-working-knowledge,
.video-editing {
  top: 1.3px;
  left: 284.01px;
  width: 98.29px;
}
.languages-working-knowledge {
  top: 10.59px;
  left: 512.39px;
  width: 260.87px;
}
.group-child6,
.group-child7 {
  position: absolute;
  top: 21px;
  left: 0;
  width: 22.73px;
  height: 1.6px;
  background-color: var(--line-color);
  object-fit: cover;
}
.group-child7 {
  left: 142.5px;
  width: 19.22px;
}
.group-child10,
.group-child11,
.group-child8,
.group-child9 {
  position: absolute;
  top: 21px;
  left: 250.12px;
  width: 24.74px;
  height: 1.6px;
  background-color: var(--line-color);
  object-fit: cover;
}
.group-child10,
.group-child11,
.group-child9 {
  left: 389.26px;
  width: 30.24px;
}
.group-child10,
.group-child11 {
  /* left: 496.39px; */
  left: 495.39px;
  left: 495.39px;
  /* left: 496.39px; */
  width: 19.21px;
  width: 76px;
  /* width: 79px; */
  
}
.group-child11 {
  /* left: 768.47px; */
  /* left: 771.47px; */
  /* left: 772.47px; */
  left: 716.47px;
  /* left: 713.47px; */
}
.d-animation-parent {
  position: relative;
  width: 787.67px;
  height: 45.3px;
}
.image-69-icon,
.image-80-icon {
  position: relative;
  width: 52.37px;
  height: 51.21px;
  object-fit: cover;
}
.image-69-icon {
  width: 51.34px;
}
.javascript,
.premiere {
  position: relative;
  line-height: 136.02%;
  display: inline-block;
  width: 67.22px;
}
.javascript {
  width: 79.57px;
}
.image-65-parent {
  width: 51.21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.image-64-icon {
  position: relative;
  width: 51.27px;
  height: 51.21px;
  object-fit: cover;
}
.software-contents-temp1 {
  width: 792.22px;
  height: 51.21px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.education-group,
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.education-group {
  text-align: center;
}
.frame-group {
  position: absolute;
  position: relative;
  /* top: 57.62px;
  left: -3.24px; */


}
.redline-icon {
  position: absolute;
  top: 365.12px;
  left: 35.59px;
  width: 891.69px;
  height: 2357.1px;
  object-fit: cover;
  display: none;
}
.letter-133 {
  background-color: var(--color-white);
  width: 979.48px;
  /* height: 2981.64px; */
  /* height: 2960.64px; */
  /* height: 3053.94px; */
  /* height: 3034px; */
  height: 3033px;
  text-align: left;
  /* font-size: var(--font-size-base); */
  color: var(--color-black);
  font-family: var(--font-inter);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  /* background: #FFF; */
  /* box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.69); */
  /* box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.55); */
  /* border: solid red 2px; */
  padding-top: 28.81px;
  padding-top: calc(28.81*2px);
  /* padding-bottom: 150px; */
  /* padding-bottom: 100px; */
  /* padding: 61.82px 0px 377.149px 0px; */
  /* padding: 61.82px 0px 377.149px 0px; */
  
  /* top: 0;
  left: 0;
  transform: scale(0.3); 
  transform-origin: top;
  position: relative; */
  
  /* box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.00);
  border: 1px rgb(207, 207, 207) solid; */


  /* box-shadow: 4px 8px 4px 0px rgba(0, 0, 0, 0.15); */
  /* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */
  /* border-radius: 17px; */
  /* border-radius: 4px/4px; */
  /* border-radius: 10px/10px; */
  /* border-radius: 2px/2px; */


  /* box-shadow: 0px 9px 19px 0px rgba(0, 0, 0, 0.1); */


/* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */
/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08)); */
/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.15)); */
/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.15)); */
/* box-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0.15); */

/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)); */
/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 1)); */

/* filter: none; */
box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .15);
/* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .2); */

font-size: 16px;


/* from carousel item */

outline: 2px hsl(0, 0%, 97%) solid;
box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.17);


/* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.15); */
}


.letter-container {
  /* background-color: var(--color-white); */
  /* background-color: pink; */
  /* width: 979.48px; */
  /* height: 2960.64px; */
  width: 100%;
  /* height: 2000px; */

  margin-left: auto;
  margin-right: auto;
  /* padding-top: 28.81px;
  padding-top: calc(28.81*2px); */


  
}

.back-to-portfolio{
  width: 979.48px;
  margin: auto;

  position: relative;
  display: flex;
flex-direction: row;
align-items: center;



margin-top: 16px;
margin-top: 24px;

padding-left: 24px;

/* padding-left: 48px; */

/* style={{height:`8px`, outline:`1px solid green`, marginTop:`16px`, }} */
}

