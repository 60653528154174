:root {
	/* --scale-factor: .5; */
	--scale-factor: 0.6;
}

.return-arrow-container {
	background-color: white;
	height: 128px;
	width: 128px;
	height: calc(128px * var(--scale-factor));
	width: calc(128px * var(--scale-factor));
	fill: #fff;
	/* stroke-width: 1px;
	stroke: #d4d4d4; */
	/* border: #d4d4d4 solid 1px; */
	/* outline: #d4d4d4 solid 1px; */
	outline: var(--stroke-color) solid 1px;
	border-radius: 128px/128px;
	/* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.15)); */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */

	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	cursor: pointer;
	/* position: fixed; */
	position: sticky;
	/* bottom: 128px; */
	bottom: 64px;
	left: 90vw;
	/* left: calc(100vw - 1px) ; */
	/* left: 85vw; */
	/* right: 256px; */
	--offset: 256px;

	/* margin-top: 100vh; */
	margin-top: calc(100vh + var(--offset));
	/* margin-top: calc(100vh + var(--offset)); */
	/* zoom: 0.5; */
	/* -moz-transform: scale(0.5); */
	/* bottom: 20px;  */

	place-self: end;
	position: sticky;
	bottom: 64px;
	margin-right: 13vh;
	/* margin-right:256px; */
	/* margin-right:170px; */
	margin-top: 100vh;
	z-index: 3;

	/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08));
    transition:  translate 100ms, scale 100ms;
    translate: 0px -5px; */

	transition: background var(--inactive-transition-speed), translate 100ms,
		scale 100ms;
	/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08)); */

	box-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0.08);

	translate: 0px -2.5px;
}

.return-arrow-container path {
	fill: var(--main-grey);
	/* stroke: var(--main-grey); */
}

.return-arrow-container rect {
	fill: var(--main-grey);
	/* stroke: var(--main-grey); */
}

.return-arrow-container svg {
	transform: scale(
		var(--scale-factor)
	); /* This will scale the SVG to twice its original size */
}

.return-arrow-container:hover {
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.00)); */
	/* border-radius: 7px/7px; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 3px solid #C6E3F7; */
	/* outline: 12px solid #C6E3F7; */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */

	/* 

  translate: 0px -5px;
  transition: translate 100ms;


  transition: translate 100ms, scale 100ms ease; */

	/* outline: 3px solid #C6E3F7; */

	/* outline: 4.2px solid #C6E3F7; */

	/* outline: 4px solid var(--highlight-color); */
	outline: var(--highlight-stroke) solid var(--highlight-color);


	

	transition: background 100ms, outline 0ms, translate 100ms, scale 100ms ease;
	/* filter: drop-shadow(0px 4px 2.5px rgba(0, 0, 0, 0.07)); */
	/* filter: drop-shadow(0px 0px 2.5px rgba(0, 0, 0, 0.07)); */

	/* box-shadow: 0px 0px 2.5px rgba(0, 0, 0, 0.07); */
	box-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0.08);
	/* scale: 102.5%;
	filter: drop-shadow(0px 3px 3.75px rgba(0, 0, 0, 0.0725));
  translate: 0px -5px; */

	/* translate: 0px -5px; */

	/* translate: 0px -10px; */
	/* translate: 0px -10px;
  scale: 102%;
  filter: drop-shadow(0px 6px 1.7px rgba(0, 0, 0, 0.075)); */
}



.return-arrow-resume-mode:hover {

	/* outline: var(--highlight-stroke) solid var(--highlight-color); */
	/* outline: var(--highlight-stroke) solid var(--main-grey); */
	/* outline: 4px solid var(--main-grey); */
	/* outline: var(--highlight-stroke) solid var(--main-grey); */
	/* outline: 3px solid #8c8c8c; */
	/* outline: 3px solid hsl(0, 0%, 89%); */

	/* outline: 3.5px solid hsl(0, 0%, 65%); */
	/* outline: 4px solid hsl(0, 0%, 87%); */
	/* outline: var(--highlight-stroke) solid hsl(0, 0%, 87%); */
	/* outline: var(--highlight-stroke) solid #94aab9; */
	

	/* outline: 3px solid hsl(0, 0%, 88%); */
	/* outline: 3px solid hsl(0, 0%, 87%); */
	/* outline: 3.5px solid hsl(0, 0%, 87%); */
	
}


.return-arrow-container:active {
	/* translate: 0px 0px;
  filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.00)); */

	transition: background 0ms, outline 0ms, translate 100ms;
	/* background-color: rgba(0, 0, 0, .01); */
	background-color: #fdfdfd;
	/* background-color: rgba(0, 0, 0, .02); */
	/* background-color: rgba(0, 0, 0, .03); */
	translate: 0px 0px;
	/* scale: 100%; */
	/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0)); */

	box-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0);


	/* outline: 1px solid #d4d4d4; */
	outline: #d4d4d4 solid 1px;
}



@media (max-width: 1024px) {
	:root {
		--scale-factor: 0.5;
		/* --scale-factor: .45;
    --scale-factor: .475; */
	}
	.return-arrow-container {
		bottom: 32px;
		margin-right: 6vh;
		/* bottom:16px;
  margin-right:16px; */
		bottom: 24px;
		margin-right: 24px;
	}


	.return-arrow-container:hover {
		/* outline: calc(var(--highlight-stroke) * .5) solid var(--highlight-color); */
		/* outline: 2px solid var(--highlight-color); */
		outline: calc(var(--highlight-stroke) * .75) solid var(--highlight-color);
		
	}
}



@media (max-width: 768px) and (orientation: portrait) {
	.return-arrow-container:hover {
		/* translate: 0px 0px;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.00)); */

		transition: background 0ms, outline 0ms, translate 100ms;
		/* background-color: rgba(0, 0, 0, .01); */
		background-color: #fdfdfd;
		/* background-color: rgba(0, 0, 0, .02); */
		/* background-color: rgba(0, 0, 0, .03); */
		translate: 0px 0px;
		/* scale: 100%; */
		/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0)); */
		/* outline: 1px solid #d4d4d4; */
		outline: #d4d4d4 solid 1px;
	}

	.return-arrow-container:active {
		/* translate: 0px 0px;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.00)); */

		transition: background 0ms, outline 0ms, translate 100ms;
		/* background-color: rgba(0, 0, 0, .01); */
		background-color: #fdfdfd;
		/* background-color: rgba(0, 0, 0, .02); */
		/* background-color: rgba(0, 0, 0, .03); */
		translate: 0px 0px;
		/* scale: 100%; */
		filter: none;
    box-shadow: none;
		/* outline: 1px solid #d4d4d4; */
		outline: #d4d4d4 solid 1px;
	}
}



