.resume-container{
  /* margin-top: 96px; */
  /* margin-top: 2.5vh; */
  /* margin-top: -5vh; */
  /* margin-top: calc(28.81*-2px); */
  /* margin-top: calc(- 28.81px - 4px); */
  /* margin-top: -40.81px ; */
  margin-top: -40.81px ;
  
  /* LINE UP TO LINE BUT MAKE SURE FIT DYNAMIC SCALING? PROB TURN OTHER GAP IN APP.JS TO PX INSTEAD OF VH */
  /* LINE UP TO LINE BUT MAKE SURE FIT DYNAMIC SCALING? PROB TURN OTHER GAP IN APP.JS TO PX INSTEAD OF VH */
  /* LINE UP TO LINE BUT MAKE SURE FIT DYNAMIC SCALING? PROB TURN OTHER GAP IN APP.JS TO PX INSTEAD OF VH */
  /* LINE UP TO LINE BUT MAKE SURE FIT DYNAMIC SCALING? PROB TURN OTHER GAP IN APP.JS TO PX INSTEAD OF VH */
  
  /* margin-top: 5vh; */
  /* position: absolute; */
  width: 100%;
  /* background-color: cadetblue; */
  /* pointer-events: none; */
  opacity: 0%;

}

.resume-container img{
  pointer-events: none;
  user-select: none;
  /* border: 2px red solid; */
}