.skills-wrapper {
	display: flex;
	width: 805.027px;
	align-items: flex-start;
	/* background-color: aquamarine; */
    /* justify-content: space-between; */
}

.quarter-inch{
    display: flex;
    /* width: 147.241px;
    height: 28.808px; */
    height: 147.241px;
    width: 28.808px;
    /* transform: rotate(90deg); */
    padding-bottom: 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;    
    /* background-color: bisque; */
}

.skill-list{
    display: flex;
width: 150.442px;
flex-direction: column;
align-items: flex-start;
gap: 28.808px;
/* background-color: lightgoldenrodyellow; */

}

.skill-content-wrapper{
    /* background-color: red; */
    display: flex;
	align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    
}

.skill-item{

    align-self: stretch;

    color: #000;
/* font-family: Inter; */
/* font-size: 16.005px; */
font-style: normal;
font-weight: 400;
line-height: 136.023%; /* 21.77px */
}

.skill-list li {
    width: 100%; /* take up full width of the container */
    text-align: left; /* align text to the left */
    /* padding-left: 1.31rem; */
    /* padding-left: .8rem; */
    padding-left: 2.05rem;
    list-style-position: outside;
    /* background-color: aliceblue; */
    text-indent: -1.4em;     
    /* padding-left: 1em;      */

}