:root {
	/* --chiclet-secondary-font: 19.75px; */
	--chiclet-secondary-font: 19.7px;
	/* --chiclet-mobile-secondary-font: 14.775px; */
	--chiclet-mobile-secondary-font: 16px;
}

.chiclet {
	/* width: 262px; */
	/* width: 183.4px; */
	/* width: 184px; */
	width: 183.4px;
	/* height: 555px; */
	height: 388.5px;
	/* height: 389px; */
	overflow: hidden;
	background-color: white;
	/* background-color: rgb(243, 243, 243); */

	/* box-shadow: inset 0px 0px 0px 2px #d4d4d4, 3px 5px 2px 0px rgba(0, 0, 0, 0.09); */

	/* box-shadow: inset 0px 0px 0px 1.4px #d4d4d4,
		2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */

	/* box-shadow: 
	
		2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */

	/* outline: 1px solid var(--stroke-color); */

	/* outline: 1px solid #d4d4d4; */
	/* outline: 1px solid hsl(0, 0%, 83%); */
	outline: 1px solid var(--stroke-color);
	/* filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(0px 3px 1.5px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(0px 3px 1.5px rgba(0, 0, 0, 0.095)); */
	/* filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.09)); */

	/* border-radius: 9.59px/9.59px; */
	border-radius: 6.713px/6.713px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	user-select: none;
	cursor: pointer;
	/* pointer-events: none; */
	/* zoom: .85; */
	/* zoom: .7; */
	background-size: 100%;
	/* transition: background-size 1000ms ease; */
	/* transition: background-size 1000ms ease, translate 100ms; */
	transition: background-size 1000ms ease, translate 100ms, scale 100ms;
	/* transition: background-size 175ms ease, translate 500ms, scale 175ms; */
	transition: background-size 200ms ease, translate 500ms, scale 200ms;

	transition: background-size 1000ms, translate 500ms, scale 200ms;
	transition: background-size 500ms, translate 500ms, scale 200ms;
	transition: background-size 250ms, translate 500ms, scale 200ms;
	
	/* transition: background-size 1500ms ease; */
	/* transition: background-size 1333ms ease; */
	/* transition: background-size 1200ms ease; */
	translate: 0px -5px;
	/* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.04); */
	background-position: center top;
	z-index: 0;

	/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08)); */
	/* filter: none; */
	/* overflow: visible; */
}

.chiclet::before {
	/* content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    background-color: black;
	filter: blur(20px); */
}

.chiclet:hover::before {
	/* background-color: red; */
}

.chiclet:hover {
	/* box-shadow: 3px 5px 2px 0px rgba(0, 0, 0, 0.09); */

	/* border-radius: 9.59px/9.59px; */
	border-radius: 6.713px/6.713px;
	/* border-radius: 7px/7px; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 4.2px solid #C6E3F7; */
	/* outline: 4.2px solid var(--highlight-color); */
	/* outline: 3.25px solid var(--highlight-color); */
	/* outline: 4px solid var(--highlight-color); */
	/* outline: 3.75px solid var(--highlight-color); */
	/* outline: var(--highlight-stroke) solid var(--highlight-color); */

	/* outline: 12px solid #C6E3F7; */
	/* background-size: 125%; */
	background-size: 110%;
	/* background-size: 105%; */
	/* background-size: 103%; */
	/* transition: background-size 500ms ease, translate 100ms; */
	transition: background-size 500ms ease, translate 100ms, scale 100ms ease;
	transition: background-size 150ms ease, translate 125ms, scale 125ms ease;
	transition: background-size 250ms ease, translate 125ms, scale 125ms ease;
	background-size: 115%;
	transition: background-size 500ms, translate 125ms, scale 125ms ease;
	background-size: 110%;
	transition: background-size 350ms, translate 125ms, scale 125ms ease;
	/* transition: background-size 200ms ease, translate 100ms, scale 200ms ease; */
	/* transition: background-size 500ms ease; */
	/* transition: background-size 300ms ease;
	transition: background-size 333ms ease;
	transition: background-size 375ms ease;
	transition: background-size 425ms ease; */
	/* transition: background-size 475ms ease; */
	/* transition: background-size 450ms ease; */
	/* translate: 0px 10px; */
	translate: 0px -10px;
	/* background-position: center 100px top 100px; */
	/* translate: 0px -20px; */
	/* translate: 0px -15px; */
	/* scale: 102.5%; */
	/* scale: 101.25%; */
	/* translate: 0px -5px; */
	/* outline: 4.2px solid #c6e3f7; */
	/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(0px 10px 3px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(0px 9px 3.5px rgba(0, 0, 0, 0.08)); */
	/* filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(0px 8px 3.5px rgba(0, 0, 0, 0.085)); */
	/* filter: drop-shadow(0px 9px 4px rgba(0, 0, 0, 0.07)); */
	/* filter: drop-shadow(0px 6.5px 4px rgba(0, 0, 0, 0.07)); */
	/* filter: drop-shadow(0px 6.5px 3.75px rgba(0, 0, 0, 0.0675)); */
	/* filter: drop-shadow(0px 7px 3.75px rgba(0, 0, 0, 0.0675)); */
	/* filter: drop-shadow(0px 4px 3.75px rgba(0, 0, 0, 0.0675)); */
	/* filter: drop-shadow(0px 4px 3.75px rgba(0, 0, 0, 0.0725)); */
	/* filter: drop-shadow(0px 3px 3.75px rgba(0, 0, 0, 0.0725)); */
	/* filter: drop-shadow(0px 4.5px 3px rgba(0, 0, 0, 0.0725)); */
	/* box-shadow: 2.1px 3.5px 12.4px 0px rgba(0, 0, 0, 0.04); */
	/* transition: translate 100ms, filter 3500ms; */
	/* transition: translate 100ms; */

	/* filter: none; */

	/* outline: none; */

	/* THIS WAS DOUBLED !! */
	/* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */

	/* outline: var(--highlight-stroke) solid var(--highlight-color); */
	/* cursor:crosshair; */
}

.chiclet:active {
	transition: background 0ms, outline 0ms, translate 100ms;
	/* background-color: rgba(0, 0, 0, .01); */
	translate: 0px 0px;
	/* filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0)); */
}

.disable-interactions {
	pointer-events: none;
}

.chiclet-img-div {
	height: 71%;
	/* z-index: 1; */

	display: flex;
	/* background-size: cover; */
	background-position: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-size: inherit;
	/* transition: background-size 1000ms ease; */
	/* background-size: 100% */
	/* color: #fff;
    justify-content: center;
	text-align: center;
	font-family:inter;
	font-size: 36px;
	font-style: italic;
	font-weight: 900;
	line-height: normal;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    paint-order: stroke fill; */
	/* box-shadow: 0px 0px 17.3px 4px #FFF inset; */
	/* background-position: center -5px;
	transition: background-position 0.1s ease-in-out; */
}

.chiclet-img-div:hover {
	/* background-position: center top; */
}

/* .chiclet-img-div:hover {
    background-size: 125%; 
    background-size: 120%; 

	transition: background-size 0.5s ease;
} */

.chiclet-title-container {
	/* margin: 0px 10% 0px 10%; */
	margin: 0px 5% 0px 5%;
	/* background: red; */
	position: relative;
	z-index: 1;
}

.chiclet-text {
	/* color: #fff; */
	-webkit-text-fill-color: white;

	text-align: center;
	font-family: Inter;
	/* font-size: 36px; */
	/* font-size: 25.2px; */
	/* font-size: 25px; */
	font-size: 25.2px;
	font-style: italic;
	font-weight: 700;
	line-height: normal;

	/* -webkit-text-fill-color: white;
     -webkit-text-stroke-width: 2px; */
	/* font-family: inter;
	font-size: 36px;
	font-style: italic;
	font-weight: 900;
	line-height: normal;  */
	display: relative;
	/* z-index: 1; */
	/* -webkit-text-fill-color: white; */
	/* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;  */
}

.chiclet-text::before {
	/* top: 0;
    left: 0; */
	left: 0;
	/* -webkit-text-stroke-width: 5px; */
	-webkit-text-stroke-width: 3.5px;
	-webkit-text-stroke-color: black;
	/* -webkit-text-fill-color: pink; */

	/* color: white; */
	content: attr(text-content);
	/* content: 'hello'; */
	position: absolute;
	z-index: -1;
	/* z-index: -1; */
	/* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black; */
	/* z-index: 0; */
	/* content: attr(data-content);  */
	/* position: absolute;
    top: 0;
    left: 0;
    z-index: -1; 
    background-color: black; 
    color: white; 
    opacity: 0.5;   */
}

.chiclet-img {
	/* height: 50%; */
	width: 100%;
}

.chiclet-bg-img {
	height: 50%;
	background: linear-gradient(316deg, #000 0%, rgba(0, 0, 0, 0) 100%);
	/* width: 100%; */
}

.chiclet-desc-container {
	height: f;
	/* background-color: peachpuff; */

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex: 1 0 0;

	/* padding-bottom: 10%; */
	padding-bottom: 9.5%;
	/* padding-bottom: 25px; */

	/* flex-shrink: 0; */
	position: relative;
	z-index: 5;
	background: white;
	width: 100%;
}

.desc-dates {
	color: #ababab;
	color: var(--main-grey);
	text-align: center;
	font-family: Inter;
	/* font-size: 28.11px; */
	/* font-size: 19.6px; */
	font-size: var(--chiclet-secondary-font);
	font-style: italic;
	font-weight: 400;
	line-height: normal;
}

.desc-role {
	color: #000;
	text-align: center;
	font-family: Inter;
	/* font-size: 19.6px; */
	font-size: var(--chiclet-secondary-font);
	font-style: italic;
	font-weight: 700;
	line-height: normal;
	/* background-color: aqua; */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
}

@media (max-width: 1024px) {
	.chiclet {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
		scale: 100%;
		filter: none;
	}
	.chiclet:hover {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
		translate: 0px -5px;
		scale: 100%;
		filter: none;
	}
	.chiclet:active {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
		translate: 0px -5px;
		scale: 100%;
		filter: none;
	}
}

@media (max-width: 768px) and (orientation: portrait) {
	.chiclet {
		/* width: 183.4px; */
		width: 100%;

		/* height: 388.5px; */
		/* height: 10vh; */
		/* height: 15vh; */
		height: 15.5vh;
		height: 128px;

		/* height: 13vh; */
		/* height: 14vh; */
		/* height: 12vh; */
		overflow: hidden;
		background-color: white;

		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);

		/* outline: none; */

		border-radius: 6.713px/6.713px;
		/* display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center; */
		user-select: none;
		cursor: pointer;
		/* zoom: .5; */
		background-size: 100%;
		transition: background-size 1000ms ease;
		/* background-color: red; */
		/* width: 90vw; */
		/* width: 70vw; */
		/* width: 80vw; */
		width: 85vw;
		/* width: calc(var(--frame-width) - 10vw); */
		width: calc(var(--frame-width) - 48px);

		translate: none;


		/* flex-shrink: 0;
align-self: stretch; */
	}

	.disable-interactions {
		pointer-events: none;
	}

	.chiclet-img-div {
		height: 71%;
		display: flex;
		background-position: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		background-size: inherit;

		background-size: cover;
		background-position: center 30%;
	}

	.chiclet-title-container {
		margin: 0px 5% 0px 5%;
		position: relative;
		z-index: 1;
	}

	.chiclet-text {
		/* color: #fff; */
		-webkit-text-fill-color: white;

		text-align: center;
		font-family: Inter;

		/* font-size: 25.2px; */
		font-size: 18.9px;
		font-style: italic;
		font-weight: 700;
		line-height: normal;

		display: relative;
	}

	.chiclet-text::before {
		left: 0;

		-webkit-text-stroke-width: 3.5px;
		-webkit-text-stroke-color: black;

		content: attr(text-content);

		position: absolute;
		z-index: -1;
	}

	.chiclet-img {
		/* height: 50%; */
		width: 100%;
	}

	.chiclet-bg-img {
		height: 50%;
		background: linear-gradient(316deg, #000 0%, rgba(0, 0, 0, 0) 100%);
	}

	.chiclet-desc-container {
		/* height: f; */

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		flex: 1 0 0;
		padding-bottom: 0px;
		padding: 2%;
		/* gap: 5%; */
		gap: 8px;
		/* background-color: red; */

		z-index: 1;

		/* padding: 2px; */
	}

	.desc-dates {
		color: #ababab;
		text-align: center;
		font-family: Inter;

		font-size: var(--chiclet-mobile-secondary-font);
		font-style: italic;
		font-weight: 400;
		line-height: normal;
	}

	.desc-role {
		color: #000;
		text-align: center;
		font-family: Inter;

		font-size: var(--chiclet-mobile-secondary-font);
		font-style: italic;
		font-weight: 700;
		line-height: normal;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1 0 0;
	}

	.chiclet:hover {
/* 
		border-radius: 6.713px/6.713px;



		background-size: 100%;

		transition: background-size 500ms ease, translate 100ms, scale 100ms ease;
		transition: background-size 150ms ease, translate 125ms, scale 125ms ease;
		transition: background-size 250ms ease, translate 125ms, scale 125ms ease;

	

		scale: 100%;

		translate: 0px -5px;

		translate: none;

		outline: 1px solid #d4d4d4; */

	}

	.chiclet:active {
		transition: background 0ms, outline 0ms, translate 100ms;
		/* background-color: rgba(0, 0, 0, .01); */
		/* translate: 0px 0px; */
		/* filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.00)); */

		/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.00)); */
		box-shadow: none;
		filter: none;
		translate: 0px -5px;
		translate: 0px -0px;
	}

	.chiclet:hover{
	
	}
}

.chiclet-container {
	/* border: 4px solid pink; */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;

	position: relative;

	border-radius: 6.713px/6.713px;
}

.chiclet-container::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;

	/* background-color: black; */
	filter: blur(1.5px);
	/* filter: blur(20px); */

	translate: 0px -3px;

	border-radius: 6.713px/6.713px;

	background-color: hsl(0, 0%, 85%);

	/* transition: all 500ms; */
	/* transition: all 400ms; */
	transition: all 500ms, background-color 250ms;
	transition: all 125ms;

	filter: blur(2px);
	background-color: hsl(0, 0%, 91%);
	translate: 0px -1px;

	filter: blur(1.75px);
	background-color: hsl(0, 0%, 93%);
	translate: 0px -2px;
	/* translate: 0px -1px; */

	/* background-color: hsl(200, 92%, 56%) ; */

	/* background-color: hsl(0, 0%, 0%) ; */
}

.chiclet-container:hover::before {
	background-color: hsl(0, 0%, 91%);

	/* filter: blur(4px); */
	filter: blur(3px);

	translate: 0px -1px;
	/* translate:  0px -2px; */
	/* translate:  0px 0px; */
	/* translate:  0px 10px; */
	/* filter: blur(3px); */
	filter: blur(3px);
	/* background-color: hsl(0, 0%, 92%) ; */
	background-color: hsl(0, 0%, 91%);
	/* background-color: hsl(0, 0%, 90%) ; */
	/* background-color: hsl(0, 93%, 50%) ; */
	/* background-color: hsl(0, 0%, 91.5%) ; */
	transition: all 500ms;

	translate: 0px 1px;
	filter: blur(3px);
	background-color: hsl(0, 0%, 93%);

	translate: 0px 1px;
	filter: blur(3px);
	background-color: hsl(0, 0%, 94%);

	/* mild edit for v2 */
	translate: 0px 0px;
	filter: blur(3px);
	background-color: hsl(0, 0%, 93.5%);
	
	/* translate: 0px 1px; */
	/* background-color: hsl(0, 0%, 94%) ;
	
	background-color: hsl(0, 0%, 0%) ;
	filter: blur(5px);
	translate:  0px -1px;
	width: 102%;
    height: 102%; */

	/* background-color: hsl(0, 0%, 94%) ;
	filter: blur(4px);
	translate:  0px -1px;
	width: 103%;
    height: 103%; */

	/* background-color: hsl(0, 0%, 0%) ; */
}




/* scale up STRONG */

/* .chiclet {
	background-size: 102%;
	translate: 0px 0px;
		transition: background-size 500ms ease, translate 500ms, scale 200ms ease;
}

.chiclet:hover {
	background-size: 100%;
	outline: var(--highlight-stroke) solid var(--highlight-color);
	translate: 0px 0px;
	scale: 103%;
	transition: background-size 350ms ease, translate 125ms, scale 125ms ease;
}

.chiclet-container::before {
	filter: blur(1.75px);
	translate: 0px 2px;
	background-color: black;
	filter: blur(0px);
	transition: 500ms all;
	background-color: hsl(0, 0%, 87%);
}

.chiclet-container:hover::before {
	background-color: hsl(0, 0%, 94%);
	filter: blur(4px);
	translate: 0px -1px;
	width: 103%;
	height: 103%;

	width: 100%;
	height: 100%;
	background-color: black;
	filter: blur(0px);

	translate: 0px 3px;

	transition: 125ms all;

	translate: 0px 25px;
	filter: blur(5px);

	translate: 0px 25px;
	background-color: hsl(0, 0%, 90%);
} */





/* scale up milder */

/* .chiclet {
	background-size: 102%;
	translate: 0px 0px;
		transition: background-size 500ms ease, translate 500ms, scale 200ms ease;
}

.chiclet:hover {
	background-size: 100%;
	outline: var(--highlight-stroke) solid var(--highlight-color);
	translate: 0px 0px;
	scale: 101.5%;
	transition: background-size 350ms ease, translate 125ms, scale 125ms ease;
}

.chiclet-container::before {
	filter: blur(1.75px);
	translate: 0px 2px;
	background-color: black;
	filter: blur(0px);
	transition: 500ms all;
	background-color: hsl(0, 0%, 87%);
}

.chiclet-container:hover::before {
	background-color: hsl(0, 0%, 94%);
	filter: blur(4px);
	translate: 0px -1px;
	width: 103%;
	height: 103%;

	width: 100%;
	height: 100%;
	background-color: black;
	filter: blur(0px);

	translate: 0px 3px;

	transition: 125ms all;

	translate: 0px 25px;
	filter: blur(5px);

	translate: 0px 15px;
	background-color: hsl(0, 0%, 90%);
} */




/* translate up and small scale */

/* .chiclet {
	background-size: 102%;
	translate: 0px 0px;
		transition: background-size 500ms ease, translate 500ms, scale 200ms ease;
}

.chiclet:hover {
	background-size: 110%;
	background-size: 100%;
	outline: var(--highlight-stroke) solid var(--highlight-color);
	outline: 3px solid var(--highlight-color);
	translate: 0px -10px;
	scale: 101.5%;
	transition: background-size 350ms ease, translate 125ms, scale 125ms ease;
}

.chiclet-container::before {
	filter: blur(1.75px);
	translate: 0px 2px;
	background-color: black;
	filter: blur(0px);
	transition: 500ms all;
	background-color: hsl(0, 0%, 87%);
}

.chiclet-container:hover::before {
	background-color: hsl(0, 0%, 94%);
	filter: blur(4px);
	translate: 0px -1px;
	width: 103%;
	height: 103%;

	width: 100%;
	height: 100%;
	background-color: black;
	filter: blur(0px);

	translate: 0px 3px;

	transition: 125ms all;

	translate: 0px 25px;
	filter: blur(5px);

	translate: 0px 10px;
	background-color: hsl(0, 0%, 90%);
} */




/* translate up and no scale.. reasonable */

.chiclet {
	background-size: 100%;
	translate: 0px -5px;
		transition: background-size 500ms ease, translate 500ms, scale 200ms ease;
		transition: background-size 600ms ease, translate 500ms, scale 200ms ease;
		/* transition: background-size 250ms ease, translate 500ms, scale 200ms ease; */
		/* transition: background-size 200ms ease, translate 500ms, scale 200ms ease; */
}

.chiclet:hover {
	background-size: 100%;
	background-size: 105%;
	outline: 3px solid var(--highlight-color);
	outline: var(--highlight-stroke) solid var(--highlight-color);
	translate: 0px -10px;
	transition: background-size 350ms ease, translate 125ms, scale 125ms ease;
	transition: background-size 125ms ease, translate 125ms, scale 125ms ease;
}

.chiclet-container::before {
filter: blur(1.75px);
translate: 0px 0px;
translate: 0px -2px;
translate: 0px -1px;
background-color: black;

filter: blur(1px);
transition: 500ms all;
background-color: hsl(0, 0%, 87%);
background-color: hsl(0, 0%, 89%);
background-color: hsl(0, 0%, 91%);
/* background-color: hsl(0, 0%, 86%); */
/* background-color: hsl(0, 0%, 92%); */
}

.chiclet-container:hover::before {
filter: blur(4px);
translate: 0px -1px;
width: 103%;
height: 103%;

width: 100%;
height: 100%;
background-color: black;
filter: blur(0px);

translate: 0px 3px;

transition: 125ms all;

translate: 0px 25px;
filter: blur(5px);
filter: blur(4px);

translate: 0px 10px;
translate: 0px 5px;
translate: 0px 3px;
/* translate: 0px 2px; */
background-color: hsl(0, 0%, 90%);
/* background-color: hsl(0, 0%, 92%); */
/* background-color: hsl(0, 0%, 92.5%); */
background-color: hsl(0, 0%, 93%);



background-color: hsl(0, 0%, 90%);
translate: 0px 2px;
translate: 0px 1px;
translate: 0px 0px;
background-color: hsl(0, 0%, 91%);
background-color: hsl(0, 0%, 93%);
/* background-color: hsl(0, 0%, 92%); */
/* background-color: hsl(0, 0%, 89%); */
/* background-color: hsl(0, 0%, 9%); */

/* filter: blur(3px); */
/* background-color: hsl(0, 0%, 93%); */
/* filter: blur(5px); */
/* background-color: hsl(0, 0%, 92%); */
}



.chiclet:active {
	transition: background 0ms, outline 0ms, translate 100ms;
	/* background-color: rgba(0, 0, 0, .01); */
	translate: 0px -1px;
	/* filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0)); */
	outline: 1px solid var(--stroke-color);
}

.chiclet-container:active::before {

	filter: blur(5px);
	filter: blur(0px);
	translate: 0px -1px;

	}

/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */
/* make sure to raise to fit so click works */


@media (max-width: 768px) and (orientation: portrait) {
.chiclet-container *{
	translate: none;
}

.chiclet-container:hover *{
	translate: none;
}


.chiclet:hover {

	outline: var(--highlight-stroke) solid var(--highlight-color);
	outline: 1px solid var(--stroke-color);


	box-shadow: 
	/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
		rgba(0, 0, 0, 0.09);


		/* box-shadow: none; */
}


}