.content-section-container{
}

.divider{
    width: 100%;
    /* width: 50%; */
    /* width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px);  */

    margin: auto;
    /* background-color: rgba(0, 0, 0, .40); */
    /* background-color: rgba(0, 0, 0, .25); */
    /* background-color: var(--stroke-color); */
    /* background-color: #cdcdcd; */
    /* background-color: #999999; */
   
    /* border-top: 1px var(--stroke-color) dashed; */
    border-top: 1px var(--stroke-color) solid;
    /* border-top: 1px var(--stroke-color-lt) solid; */

    /* background-color: red; */
/* margin-top: 8px; */
height: 0px;
}

.sub-divider{
    width: 100%;

      /* width: calc(var(--single-frame-width) * 1.5);  */
      /* width: calc(var(--single-frame-width) * 1.25);  */
    
    /* width: var(--single-frame-width); */
    /* width: 40%; */
    /* width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px);  */
    
    margin: auto;
    
    
    /* width: calc(var(--single-frame-width) - var(--thumb-width));  */
    /* margin-top: 12px; */
    /* margin-top: 24px; */
    
    /* border-top: 1px var(--stroke-color) solid; */
    
    
    /* width: var(--single-frame-width); */
        /* border-top: 1px var(--stroke-color) solid; */
        /* border-top: 1px var(--stroke-color) solid; */
    /* display: none; */


    /* background-color: rgba(0, 0, 0, .40); */
    /* background-color: rgba(0, 0, 0, .25); */
    /* background-color: var(--stroke-color); */
    /* background-color: #cdcdcd; */
    /* background-color: #999999; */
    height: 0px;


/* width: var(--single-frame-width); */
/* height: 1px; */
border-top: 1px var(--stroke-color) solid;
border-top: 1px var(--stroke-color-lt) solid;
margin-top: 0px;
margin-bottom: 0px;
/* width: calc(var(--single-frame-width) - var(--thumb-width)); */
/* background-color: var(--stroke-color); */

/* display: none; */
    /* border-top: 1px var(--stroke-color) dashed; */
    /* border-top: 1px var(--stroke-color) solid; */
    
    
    /* for fancy */
    /* for fancy */
    /* for fancy */
    
    /* width: 100%;  */
    /* border: none; */
    /* height: 1px; */

      /* for fancy */
    /* for fancy */
    /* for fancy */

    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 20%, transparent 25%, transparent  75%, transparent 80%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 25%, transparent 30%, transparent  70%, transparent 75%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 30%, transparent 35%, transparent  65%, transparent 70%, var(--stroke-color) 100%); */
 
    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 35%, transparent 40%, transparent  60%, transparent 65%, var(--stroke-color) 100%); */
   
    
    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 40%, transparent 45%, transparent  55%, transparent 60%, var(--stroke-color) 100%); */
    
    /* background: linear-gradient(to right, var(--stroke-color) 25%, transparent 35%, transparent  70%, var(--stroke-color) 75%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, var(--stroke-color) 30%, transparent 35%, transparent  65%, var(--stroke-color) 70%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, var(--stroke-color) 15%, transparent 20%, transparent  80%, var(--stroke-color) 85%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, transparent 15%, var(--stroke-color) 20%, var(--stroke-color)  80%, transparent 85%, transparent 100%); */
    /* background: linear-gradient(to right, transparent 20%, var(--stroke-color) 25%, var(--stroke-color)  75%, transparent 80%, transparent 100%); */
    
    /* height: 24px; */
    /* background: linear-gradient(to right, var(--stroke-color) 0%, var(--stroke-color) 7.5%, transparent 7.5%, transparent  92.5%, var(--stroke-color) 92.5%, var(--stroke-color) 100%); */
    /* display: none; */

    /* background: linear-gradient(to right, var(--stroke-color) 0%, transparent 20%, transparent 25%, transparent  75%, transparent 80%, var(--stroke-color) 100%); */
    /* background: linear-gradient(to right, var(--stroke-color) 20%, transparent 20%, transparent 20%, transparent  80%, transparent 80%, var(--stroke-color) 80%); */

    /* background: linear-gradient(to right, transparent 15%, var(--stroke-color) 35%, var(--stroke-color)  65%, transparent 85%, transparent 100%); */
    display: none;



/*     
background: linear-gradient(to right,
var(--stroke-color) calc((100% - var(--single-frame-width)) / 2),
transparent calc((100% - var(--single-frame-width)) / 2),         
transparent calc((100% - var(--single-frame-width)) / 2 + var(--single-frame-width)), 
var(--stroke-color) calc((100% - var(--single-frame-width)) / 2 + var(--single-frame-width)), 
var(--stroke-color) 100% 
) */



}







@media (max-width: 1024px) and (orientation: portrait) {
	.sub-divider {
	/* display: none; */
	}
}


/* .sub-divider{
    
    width: var(--single-frame-width);
    
    height: 0px;
    margin: auto;
    
    border-top: 1px var(--stroke-color) dashed;
    
} */

