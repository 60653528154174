:root {
	/* --thumb-container-gap: 16px; */
	--thumb-container-gap: 16px;
	/* --pos-indicator-gap: 16px; */
	/* --pos-indicator-gap: 12px; */
	/* --pos-indicator-gap: 8px; */
	--pos-indicator-gap: 10px;
}

.thumb-container-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	position: relative;

	/* padding-top: 97px; */
	/* padding-top: 194px; */
	padding-top: 167px;
	padding-top: 175px;
	padding-top: 163px;
	padding-top: 78px;
	padding-top: 106px;
	/* padding-top: 71px; */
	/* padding-top: 162px; */
	/* padding-top: 196px; */
	gap: var(--pos-indicator-gap);
}

.thumb-container {
	/* background-color: rgb(226, 255, 219); */
	/* padding: 16px; */
	/* padding: 0px 16px 0px 16px; */
	user-select: none;

	/* padding-left: 16px;
	padding-right: 16px; */
	/* padding-top: 45px; */
	/* padding-top: 55px; */

	/* padding-top: 61px; */
	margin-right: 48px;

	display: flex;
	flex-direction: column;
	/* gap: 12px; */
	gap: var(--thumb-container-gap);

	/* why isn't this 96?? it works.. */
	/* cursor: alias; */
}

.thumb-pos-indicator-container {
	/* background-color: red; */
	/* gap: 12px; */
	/* padding-top: 1px; */
	/* padding-top: 2px; */
	/* ???? */
	gap: var(--thumb-container-gap);
	/* gap: calc(var(--thumb-container-gap) - .5px); */

	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	/* align-items: flex-start; */
	/* position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    gap: var(--thumb-container-gap); */

	display: flex;
	flex-direction: column;
	height: 100%;
	/* background-color: red; */
	overflow: hidden;
	position: relative;

	/* border: 2px solid blue; */
	/* padding-left: 50px; */
}

.thumb-pos-filler {
	/* height: calc( (var(--thumb-width) * var(--thumb-ratio)) + 12px + 2px); */
	/* height: calc((var(--thumb-width) * var(--thumb-ratio))); */
	/* height: calc(160px * .53); */
	height: calc(var(--thumb-width) * var(--thumb-ratio));

	/* --thumb-width: 160px;
	--thumb-ratio: 0.53; */

	/* height: calc( (var(--thumb-width) * var(--thumb-ratio)) + .5px ); */
	/* border: 1px black solid; */
	/* outline: 1px solid black; */
	/* outline: 1px solid black; */
	/* outline: 1px solid red; */
	/* margin-bottom: 16px; */
	/* width: 8px; */
	width: 10px;
	/* background-color: red; */
}

.thumb-pos-active {
	/* height: calc((var(--thumb-width) * var(--thumb-ratio))); */
	height: calc(var(--thumb-width) * var(--thumb-ratio));
	/* height: calc(160px * .53); */
	/* height: calc( (var(--thumb-width) * var(--thumb-ratio)) + .5px ); */
	/* border: 1px black solid; */
	/* outline: 1px solid black; */
	/* outline: 1px solid black; */
	/* outline: 1px solid red; */
	/* margin-bottom: 16px; */
	/* width: 8px; */
	/* width: 6px; */
	width: 4px;
	/* width: .5px; */
	/* width: 3px; */
	/* outline: var(--activity-blue) 2px solid; */
	outline: var(--activity-blue) 1px solid;
	/* outline: black 1px solid; */
	background-color: var(--activity-blue);
	/* border-radius: 4px/4px; */
	position: absolute;
	/* transition: transform 300ms ease-in-out; */
	/* transition: top 500ms ease-in-out; */
	/* transition: top 300ms ease-in-out; */
	/* transition: top 500ms ease-in-out; */
	/* transition: top 350ms ease-in-out;	 */
	/* transition: top 175ms ease-in-out; */
	/* transition: top 1750ms ease-in-out; */

	/* transition: top 500ms cubic-bezier(0.19, 1, 0.22, 1); */
	transition: top 1000ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: top 600ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: top var(--slow-scroll-speed);
	/* transition: top 325ms ease-out; */

	/* transition: top 200ms cubic-bezier(.17,.31,.66,1.24); */
	/* transition: top 125ms cubic-bezier(.27,-0.3,.72,1.27); */

	/* transition: top 150ms cubic-bezier(0,.3,1,.74); */

	/* clicky */

	/* transition: top 850ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* transition: top 650ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* transition: top 125ms ease-in-out; */
	/* transition: transform 150ms ease-in-out; */
	/* transition: transform 125ms ease-in-out; */
	/* transition: transform 75ms ease-in-out; */
	/* transition: transform 300ms ease; */
	/* transition: transform 300ms; */
	/* transition: transform 350ms ease-in-out; */
}

.thumb-pos-indicator-container-instant {
	transition: 0ms all;
}

.indicator-text {
	/* font-size: 36px; */
	font-size: 36px;
	position: absolute;
	/* left: -36px; */
	left: -32px;
	/* left: -24px; */
	top: 25%;
	color: var(--activity-blue);
	scale: 80%;

	/* background-color: var(--activity-blue);
	width: 36px;
	height: 36px;
	border-radius: 100%;
	color: white;
	font-size: 24px;
	text-align: center;
	align-items: center;
	left: -30px; */
}

.unit {
	height: calc((var(--thumb-width) * var(--thumb-ratio)));
	width: 1px;
	position: absolute;
}

/* @media only screen and (max-width: 1280px) and (orientation: landscape) { */
@media only screen and (max-width: 1280px) {
	.thumb-container {
		margin-right: 0px;
		/* padding-top: 82px; */
	}
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
	.thumb-container-container {
		padding-top: 73px;
		padding-top: 65px;
		padding-top: 70px;
		/* padding-top: 71px; */
	}

	.thumb-container {
		margin-right: 0px;
		/* padding-top: 82px; */
	}
}

.thumb {
	/* outline: 0.7px solid rgba(0, 0, 0, 0.25); */
	/* border: 0.7px solid rgba(0, 0, 0, 0.25); */
	/* border: 1px solid rgba(0, 0, 0, 0.5); */
	border: 1px solid var(--stroke-color);
	opacity: 50%;
	/* opacity: 36%; */
	/* opacity: 38%; */
	/* opacity: 45%; */
	/* opacity: 80%; */
	/* filter: brightness(0.8); */

	/* width: 188px;
	height: 90px; */
	width: var(--thumb-width);
	height: calc(var(--thumb-width) * var(--thumb-ratio));
	/* width: 165px;
	height: 84px; */
	/* margin-bottom: 12px; */
	/* margin-bottom: 22px; */
	object-fit: cover;
	object-position: top center;
	/* background-size: cover; */
	overflow: hidden;
	/* transition: height 150ms,width 300ms; */
	/* cursor: crosshair; */
	transition: translate 100ms;
	/* filter: drop-shadow(0px 3px 1.5px rgba(0, 0, 0, 0.15)); */

	/* margin-bottom: 12px; */

	border: none;
	outline: 1px solid var(--stroke-color);
	/* cursor:default; */
}

.thumb-immediate-frame {
	width: var(--thumb-width);
	height: calc(var(--thumb-width) * var(--thumb-ratio));
}

.thumb:hover {
	/* outline: 3px solid rgba(0, 0, 0, 0.15); */
	/* outline: 3px solid rgba(0, 0, 0, 0.15); */
	/* outline: 2.5px solid #C6E3F7; */
	/* border: 1px solid rgba(0, 0, 0, 0.25); */
	/* border: 1px solid rgba(0, 0, 0, 0.0); */
	/* border: 1px solid #C6E3F7; */
	/* border-radius: 2px/2px; */
	/* translate: 0px -5px; */
	/* translate: 5px -5px; */
	/* translate: 5px -5px; */
	/* translate: 5px 0px; */
	/* translate: 0px -5px; */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */
	/* filter: drop-shadow(-2px 4px 2px rgba(0, 0, 0, 0.09)); */
}

.hover {
	/* border: 0.7px solid #B6E1FF;
	outline: 3px solid #B6E1FF; */
	/* border: 0.7px solid rgba(0, 0, 0, .25);
	outline: 3px solid rgba(0, 0, 0, .25); */
	/* border-radius: 2.5%; */
	/* border-right: 5px solid #B6E1FF;
    border-left: 5px solid #B6E1FF; */
	border-right: 5px solid rgba(0, 0, 0, 0.15);
	border-left: 5px solid rgba(0, 0, 0, 0.15);

	/* opacity: 100%; */
	/* margin-left: 20px; */
	/* transition: margin 0.3s ; */
	/* width: calc(165px*2); */
	/* height: calc(84px*2); */
}

.thumb-current {
	opacity: 100%;
	filter: brightness(1);
	/* border: 1px solid rgba(0, 0, 0, 0.25); */
	outline: 1px solid var(--stroke-color);
	/* border: 1px solid #C6E3F7; */
	/* outline: 1px solid rgba(0, 0, 0, .25); */
	/* height: calc(84px*2); */
	/* border-right: 6px solid black; */

	/* width: calc(165px*1.5); */
	/* width: calc(var(--thumb-width) * 1.05) ; */
	/* outline: 1px solid rgba(0, 0, 0, .20); */
	/* outline: 3px solid #C6E3F7; */
	/* outline: var(--stroke-color) 3px solid; */
	/* outline: 2.5px solid #C6E3F7; */
	/* outline: calc(.5 * var(--highlight-stroke)) solid var(--highlight-color); */
	/* outline: var(--highlight-color) 3px solid; */

	/* translate: 0px -5px; */
	/* translate: 5px -5px; */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */
	/* border-radius: 1px;
    border: 3px white solid;
    outline: 2px #cdcdcd solid; */
}

.thumb-pos-div {
	display: unset;
	/* display: none; */
}

.thumb-pos-div-mobile {
	display: none;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
	.thumb-pos-div {
		display: none;
	}

	.thumb-pos-div-mobile {
		display: unset;
		/* display: none; */
	}

	:root {
		/* --pos-indicator-gap: 8px; */
		/* --pos-indicator-gap: .75vw; */
		--pos-indicator-gap: 1.25vw;
		--pos-indicator-gap: var(--mobile-thumb-pos-ind-gap);
		/* --pos-indicator-gap: 1.5vw; */
	}

	.thumb-pos-filler {
		width: 0.5vw;
		width: var(--mobile-thumb-pos-ind-width);
		/* --mobile-thumb-pos-ind-gap: 1.25vw; */
		/* --mobile-thumb-pos-ind-width: .5vw; */
	}

	.thumb-pos-active {
		width: 0.5vw;
		width: var(--mobile-thumb-pos-ind-width);
	}
}
