:root {
	--view-width: 45vw;
}

img.carousel-img {
	/* width: 40vw; */
	max-width: var(--frame-width);
	/* max-height: 80vh; */
	/* jumbo ^*/
	/* max-height: 65vh; good */
	/* max-height: 75vh; */

	max-height: 80vh;

	/* max-height: 75vh; */
	max-height: 77.5vh;
	

	/* max-height: 100vh; */
	max-height: var(--frame-height);
	/* also in js */

	/* max-height: 75vh; */
	/* max-height: 77.5vh; */
	user-select: none;
	/* outline: 4px green solid; */
	/* border: 1px solid var(--stroke-color); */
	/* border: 1px solid red; */
	/* outline: 4px hsl(0, 0%, 82%) solid; */
	/* outline: 4px hsl(0, 0%, 72%) solid; */
	/* outline: 4px hsl(0, 0%, 50%) solid; */
	/* outline: 4px hsl(0, 0%, 50%) solid; */
	/* outline: 4px hsl(0, 0%, 50%) solid; */
	/* outline: 1px hsl(0, 0%, 50%) solid; */
	/* outline: 4px hsl(0, 0%, 50%) solid; */
	/* outline: 2px hsl(0, 0%, 72%) solid; */
	/* outline: 2px hsl(0, 0%, 62%) solid; */
	/* outline: 2px hsl(0, 0%, 97%) solid; */
	outline: 2px hsl(0, 0%, 97%) solid;
	/* outline: 3px hsl(0, 0%, 97%) solid; */


	/* border-radius: 4px/4px; */
	/* border-radius: 1px/1px; */
	/* height: auto; */
	/* border-radius: 4px/4px; */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25); */
	/* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15); */
	/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07); */
	/* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25); */
	/* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15); */
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.07); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.157); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1); */
	/* box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.15); */
	/* box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.09); */

	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)); */
	/* filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15)); */
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5)); */
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4)); */
	
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 1)); */
	/* filter: none; */
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 1);
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25); */
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.20); */
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.17);
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.18);
	
	
	/* box-shadow: 0px 10px 15px 10px rgba(0, 0, 0, 0.04); */



	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25); */
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.22); */
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.20); */
	/* box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.17); */


/* box-shadow: none; */
/* outline: none; */
/* border-radius: 2px/2px; */
/* border-radius: 4px/4px; */
/* outline: 2px hsl(0, 0%, 60%) solid; */
	/* to match chiclet? */
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.13); */
	/* box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.13); */

	/* outline: none; */
	/* box-shadow: none; */
	/* outline: var(--stroke-color) 1px solid; */
	/* box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.05); */
	/* outline-offset: 2px; */
	/* outline-offset: 8px; */
	/* border-radius: 8px/8px; */

}

.item-border-style{
	outline: 2px hsl(0, 0%, 97%) solid;
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)); */
	background-color: var(--carousel-ground-color);
	background-color: black;
	
	/* filter: none; */
	/* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15); */
	box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);

	/* border: none; */
}

@media (max-width: 1024px) and (orientation: portrait) {


	.item-border-style{
		
		box-shadow: none;
		outline: none;

	}

	img.carousel-img {
		box-shadow: none;
		outline: none;
	
	}

}

@media (max-width: 1024px) and (orientation: landscape) {


	.item-border-style{
		
		box-shadow: none;
		outline: none;

	}

	img.carousel-img {
		box-shadow: none;
		outline: none;
	
	}

}
/* @media (min-width: 1921px) {
	img.carousel-img {
		max-height: 75vh;
		max-height: 77.5vh;
	}
}

@media (max-width: 1024px) and (orientation: landscape) {
	img.carousel-img {
		max-height: 75vh;
		max-height: 77.5vh;

	}
}

@media (max-width: 1920px) and (min-width: 1023px) {
	img.carousel-img {
		max-height: 75vh;
		max-height: 77.5vh;
	}
}

@media (max-width: 1024px) and (orientation: portrait) {
	img.carousel-img {
		max-height: 75vh;
		max-height: 77.5vh;
	}
} */

.img-container {
	/* max-width: 100%;  */
	user-select: none;
	width: var(--frame-width);
	/* border: 4px solid green; */
	/* width: 100%; */
	/* border-radius: 8px; */
}

.figma-info-text::before {
	/* top: 0;
      left: 0; */
	/* left: 0; */

	/* -webkit-text-stroke-width: 5px; */
	/* -webkit-text-stroke-width: 3.5px; */
	/* -webkit-text-stroke-width: 3px; */
	/* -webkit-text-stroke-width: 2.5px;
    -webkit-text-stroke-color: rgba(245, 245, 245, 0.75); */
	/* -webkit-text-stroke-color: rgb(245, 245, 245); */
	/* -webkit-text-fill-color: pink; */

	/* color: white; */
	content: attr(text-content);
	/* content: 'hello'; */
	position: absolute;
	z-index: -1;
	/* z-index: -1; */
	/* -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: black; */
	/* z-index: 0; */
	/* content: attr(data-content);  */
	/* position: absolute;
      top: 0;
      left: 0;
      z-index: -1; 
      background-color: black; 
      color: white; 
      opacity: 0.5;   */

	left: 0;

	font-style: italic;
	/* padding: 8px; */
	color: rgb(90, 90, 90);
	padding-left: 48px;
	padding-right: 48px;
	/* text-shadow: 1px 1px 3px rgba(245, 245, 245, 1); */
	backdrop-filter: blur(4px);
	/* filter: blur(5px); */
	background-color: rgba(245, 245, 245, 0.25);
	/* top:95%; */

	/* width: 100%; */
}

.figma-interactive-font-div::before {
	content: attr(text-content);
	position: absolute;
	z-index: -1;

	left: 0;
	-webkit-text-stroke-width: 8px;
	/* -webkit-text-stroke-width: 4px; */
	/* -webkit-text-stroke-width: 4px; */
	/* -webkit-text-stroke-width: 5px; */
	/* -webkit-text-stroke-color: rgba(245,245,245, .35); */
	/* -webkit-text-stroke-color: rgba(245, 245, 245, 1); */
	-webkit-text-stroke-color: rgb(245, 245, 245);
	/* -webkit-text-stroke-color: rgba(245,245,245, .5); */
	/* -webkit-text-stroke-color: rgba(245,245,245, .4); */
	/* -webkit-text-stroke-color: rgba(245,245,245, 1); */
	/* -webkit-text-stroke-color: rgb(0, 0, 0); */
	/* color: rgb(90, 90, 90); */
	/* padding-left: 48px;
	padding-right: 48px; */
	/* backdrop-filter: blur(4px); */
	/* background-color: rgba(245, 245, 245, 0.25); */
}
