.xcarousel-information-static {
	user-select: none;

	/* background-color: rgb(255, 245, 102); */

	align-self: stretch;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	/* gap: 8px; */
	/* gap: 6px; */
	/* padding-left: 96px; */
	/* align-self: stretch; */

	gap: 8px;
	margin-top: 10px;
	margin-bottom: 10px;

	/* height: auto;
	align-self: stretch;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px; */

	/* flex-grow: 1; */
}

.carousel-information-static {
	padding-left: 0px;
	/* background-color: red; */

	/* width: var(--single-frame-width); */
}



.slide-information-single {
	align-self: stretch;
	justify-content: center;
	user-select: none;
	/* width: calc( var(--frame-width) - 10vw); */
	width: calc(var(--single-frame-width) - 10vw);
	/* width: var(--frame-width); */
	margin: auto;
}

@media (max-width: 1024px) {
	/* @media (max-width: 1280px) and (orientation: portrait) { */
	.carousel-information-static {
		width: var(--single-frame-width);
		/* background-color: lightblue; */
		align-self: center;
	}
}

@media (max-width: 1024px) and (orientation: portrait) {
	/* @media (max-width: 1280px) and (orientation: portrait) { */
	.carousel-information-static {
		width: calc(var(--single-frame-width) - 48px );
		/* background-color: yellow; */
		align-self: center;
	}


	.carousel-information-p-single-mobile{
		margin-left: auto;
		margin-right: auto;
		/* margin-top: 0px; */
		/* margin: auto; */
		/* margin-top: calc(var(--vert-spacing-unit) - 16px); */
		margin-top: calc(var(--vert-spacing-unit) - 8px);
		margin-bottom: 0px;
	}


}
