:root {
	/* --view-width: 45vw; */
	--view-width: calc(
		var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px
	);
}

img.carousel-single-img {
	/* width: 40vw; */
	max-width: var(--frame-width);
	/* max-width: calc( var(--frame-width) - var(--mat-margin) ); */
	/* max-height: 80vh; */
	/* jumbo ^*/
	/* max-height: 65vh; good */
	/* max-height: 75vh; */
	/* max-height: 100vh; */
	max-height: 70vh;
	user-select: none;

	/* height: auto; */
	/* border-radius: 4px/4px; */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.157); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1); */
	/* box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.15); */
}

.single-img-container {
	/* max-width: 100%;  */
	user-select: none;
	width: var(--frame-width);
	/* border: 4px solid green; */
	/* width: 100%; */
}

.figma-interactive-font-div::before {
	content: attr(text-content);
	position: absolute;
	z-index: -1;

	left: 0;
	/* -webkit-text-stroke-width: 8px; */
	/* -webkit-text-stroke-color: rgba(245,245,245, .5); */
	/* -webkit-text-stroke-width: 4px;
	-webkit-text-stroke-color: rgba(245,245,245, .75); */
	/* -webkit-text-stroke-width: 5px; */
	-webkit-text-stroke-width: 3.5px;
	/* -webkit-text-stroke-color: rgba(245,245,245, .6); */
	-webkit-text-stroke-color: rgba(245, 245, 245, 1);
	/* -webkit-text-stroke-width: 5px; */
	/* -webkit-text-stroke-color: rgba(245,245,245, .35); */
	/* -webkit-text-stroke-color: rgba(245,245,245, .4); */
	/* -webkit-text-stroke-color: rgba(245,245,245, .45); */
	/* -webkit-text-stroke-color: rgba(245,245,245, .5); */
	/* -webkit-text-stroke-color: rgba(245,245,245, 1); */
	/* -webkit-text-stroke-color: rgb(0, 0, 0); */
	/* color: rgb(90, 90, 90); */
	/* padding-left: 48px;
	padding-right: 48px; */
	/* backdrop-filter: blur(4px); */
	/* background-color: rgba(245, 245, 245, 0.25); */
}
