@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	/* --frame-width: 50vw; */
	/* --frame-width: 47.5vw; */
	/* --frame-width: 45vw; */
	/* ^good */
	/* --frame-width: 1152px; */
	/* --frame-width: 60vw; */
	/* --frame-width:60vw; */

	/* WHEN UPDATING NEED TO UPDATE CAROUSEL VIEW BUT ALSO CAROUSEL ITEM */

	/* --pillar-gap: 4vw; */
	--pillar-gap: 2vw;
	/* --pillar-gap: 1.5vw; */
	/* --thumb-width: 165px; */
	--thumb-width: 160px;
	/* --thumb-ratio: .51; */
	--thumb-ratio: 0.53;
	--highlight-color: #c6e3f7;
	/* --highlight-stroke: 4.25px; */
	--highlight-stroke: 4px;
	/* --highlight-stroke: 3px; */

	--mobile-width: 1024px;

	/* --mobile-font-size: .95rem; */
	/* --mobile-font-size: 12px; */
	/* --mobile-font-size: 13px; */
	/* --mobile-font-size: .9rem; */
	/* --mobile-font-size: 0.9rem; */
	--mobile-font-size: 0.95rem;
	--tablet-font-size: 1rem;
	--desktop-font-size: 1rem;

	--tablet-font-size: 16px;
	--desktop-font-size: 16px;
	--mobile-font-size: 14px;
	--sm-grey-text: grey;


	/* --tablet-font-size: 14px; */
	--tablet-font-size: 16px;
	--desktop-font-size: 16px;
	--mobile-font-size: 14px;
	--sm-grey-text: grey;

	/* --sm-grey-text: #9c9c9c; */
	/* --sm-grey-text: #ababab; */
	/* --sm-grey-text: #cdcdcd; */
	/* --sm-grey-text: red; */

	/*   
    --thumb-ratio: .51; 
     --thumb-width: 165px; 
     --pillar-gap: 4vw; 
     --frame-width: 45vw;  */

	--mobile-margin: 24px;
	/* --stroke-color: rgba(0, 0, 0, 0.15); */
	/* --stroke-color: hsl(0, 0%, 75%); */
	/* --stroke-color: hsl(0, 0%, 82%); */
	/* --stroke-color: hsl(0, 0%, 73%); */
	--stroke-color: hsl(0, 0%, 80%);
	/* --stroke-color: hsl(0, 0%, 70%); */
	--stroke-color: hsl(0, 0%, 77%);
	--stroke-color: hsl(0, 0%, 72%);
	--stroke-color: hsl(0, 0%, 74%);
	--stroke-color: hsl(0, 0%, 78%);
	/* --stroke-color: hsl(0, 0%, 75%); */
	/* --stroke-color: hsl(0, 0%, 77%); */
	/* --stroke-color: hsl(0, 0%, 77%); */
	/* --stroke-color: hsl(0, 0%, 73%); */
	/* --stroke-color: hsl(0, 0%, 72%); */
	/* --stroke-color: hsl(0, 0%, 85%); */
	/* --stroke-color: hsl(0, 0%, 67%); */
	/* --stroke-color: hsl(0, 0%, 78%); */
	--stroke-color-lt: hsl(0, 0%, 85%);
	--stroke-color-lt: hsl(0, 0%, 85%);
	--stroke-color-lt: hsl(0, 0%, 80%);
	/* --stroke-color: hsl(0, 0%, 82%); */
	/* --carousel-ground: hsl(0, 0%,90%); */
	--carousel-ground-color: hsl(0, 0%, 92%);
	--carousel-ground-color: hsl(0, 0%, 91%);
	
	/* --carousel-ground-color: hsl(0, 0%, 93%); */
	/* --carousel-ground-color: hsl(0, 0%, 92%); */

	--grey-divider-col: hsl(0, 0%, 91%);
	/* --grey-divider-col: hsl(0, 0%, 92%); */

	/* --carousel-ground-color: hsl(0, 0%, 90%); */
	/* --carousel-ground-color: hsl(0, 0%, 0%); */
	/* --carousel-ground-color: hsl(0, 0%, 24%); */
	/* --carousel-ground-color: hsl(0, 0%, 91%); */
	/* --carousel-ground-color: pink; */
	/* --stroke-color: hsl(0, 0%, 83%); */
	/* --stroke-color: red; */
	/* --stroke-color: #cdcdcd; */
	/* --stroke-color: rgba(0, 0, 0, 0.25); */
	/* --main-grey: rgb(136, 136, 136); */
	--main-grey: rgb(156, 156, 156);
	--main-grey: hsl(0, 0%, 61%);

	/* // from fig doc */
	--main-grey: hsl(0, 0%, 53%); 
	/* // from fig doc */
	
	/* keep landing */
	/* --main-grey: hsl(0, 0%, 58%); */
	/* keep landing */
	
	/* second check */
	--main-grey: hsl(0, 0%, 56%); 
	/* second check */


/* // now trying incorporating arrow container */


/* // 56 for main try, this for alt try*/
/* --main-grey: hsl(0, 0%, 53%);  */
/* // 56 for main try, this for alt try */


/* --main-grey: hsl(0, 0%, 56%);  */
/* --main-grey: hsl(0, 0%, 61%); */

/* // now trying incorporating arrow container */

	/* --main-grey: hsl(0, 0%, 67%); */
	
	/* --main-grey: hsl(0, 64%, 30%); */
	/* --main-grey: hsl(0, 0%, 60%); */
	/* --main-grey: hsl(0, 0%, 65%); */
	/* --main-grey: hsl(0, 0%, 56%); */
	/* --main-grey: hsl(0, 0%, 50%); */
	/* --main-grey: hsl(0, 0%, 60%); */
	/* --main-grey: hsl(0, 0%, 61%); */
	/* --main-grey: rgb(213, 22, 22); */
	--bold-weight: 600;
	--frame-height: 75vh;
	/* --frame-height: 80vh; */
	--frame-height: 75.5vh;
	/* --frame-height: 76vh; */

	/* original */
	/* --frame-height: 77.5vh; */

	--mat-margin: 32px;
	/* --mat-margin: 36px; */
	/* --mat-margin: 38px; */
	/* --mat-margin: 40px; */
	/* --mat-margin: 42px; */
	/* --mat-margin: 48px; */
	/* --mat-margin: 0px; */

	--vert-spacing-unit: 32px;
	/* --vert-spacing-unit: 38px; */

	/* --vert-spacing-unit: 28px; */
/* +4 -4 w/ 36px */


/* --mat-margin: 0px; */

	/* --vert-spacing-unit: 24px; */
	/* --vert-spacing-unit: var(--mat-margin); */
	/* --vert-spacing-unit: calc( var(--mat-margin) * .66 ); */
	/* --vert-spacing-unit: calc( var(--mat-margin) * .66 ); */
	/* --vert-spacing-unit: 24px; */

	/* --vert-spacing-unit: 0px` */




	--slow-scroll-speed: 350ms ease-out;
	/* --slow-scroll-speed: 300ms; */
	/* --slow-scroll-speed: 325ms ease-out; */
	/* --slow-scroll-speed: 1000ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* --slow-scroll-speed: 600ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* --slow-scroll-speed: 1000ms cubic-bezier(0.19, 1, 0.22, 1); */
	--slow-scroll-speed: 800ms cubic-bezier(0.19, 1, 0.22, 1);
	/* --slow-scroll-speed: 750ms cubic-bezier(0.19, 1, 0.22, 1); */
	/* --slow-scroll-speed: 700ms cubic-bezier(0.19, 1, 0.22, 1); */
	
	/* --slow-scroll-speed: 750ms cubic-bezier(.2,.36,.47,1); */
	/* --slow-scroll-speed: 500ms cubic-bezier(.19,.65,.22,1); */
	/* --slow-scroll-speed: 500ms cubic-bezier(.11,.39,.22,1); */
	/* --slow-scroll-speed: 500ms cubic-bezier(.24,.78,.18,1); */

	/* --slow-scroll-speed: 800ms cubic-bezier(.24,.78,.18,1); */
	
	/* this area? */
	/* --slow-scroll-speed: 600ms cubic-bezier(.24,.78,.18,1); */
	/* --slow-scroll-speed: 550ms cubic-bezier(.24,.78,.18,1); */
	--slow-scroll-speed: 525ms cubic-bezier(.24,.78,.18,1);
	/* --slow-scroll-speed: 500ms cubic-bezier(.24,.78,.18,1); */
	/* this area? */
	
	--slow-scroll-speed: 525ms cubic-bezier(.27,.76,.51,.99);
	
	/* --slow-scroll-speed: 350ms cubic-bezier(.24,.78,.18,1); */
	
	/* --slow-scroll-speed: 650ms cubic-bezier(.24,.78,.18,1); */


	/* --slow-scroll-speed: 525ms cubic-bezier(.24,.78,.18,1); */
	

	/* --common-spacing: 2; */
	--common-spacing: 1.5;
	/* --common-spacing: 1.25; */
	/* --spacing-unit-above-carousel: 3; */
	/* --spacing-unit-below-carousel: 2; */
	--spacing-unit-above-carousel: calc(var(--common-spacing) + 1);
	--spacing-unit-below-carousel: var(--common-spacing);
	/* --spacing-unit-below-section-header: 1.5; */
	/* --spacing-unit-below-section-header: 1.25; */
	--spacing-unit-below-section-header: 1.5;
	--spacing-unit-above-section-header: 3;
	/* --spacing-unit-above-section-header: 2.5; */

	--common-spacing: 1.25;
	--spacing-unit-above-section-header: 2.5;
	--spacing-unit-below-section-header: 1.25;

	--common-spacing: 1.75;
	--spacing-unit-above-section-header: 3;
	--spacing-unit-below-section-header: 1.75;

	--common-spacing: 1.5;
	--spacing-unit-above-section-header: 3;
	--spacing-unit-below-section-header: 1.5;
	--spacing-unit-below-section-header: 1;
	--common-spacing: 1.5;
	--spacing-unit-above-section-header: 2.5;
	--spacing-unit-below-section-header: 0;

	--spacing-unit-below-main-header: 3;
	--spacing-unit-below-main-header: 2.5;

	/* --spacing-unit-below-main-header: 3; */
	/* --spacing-unit-above-section-header: 3; */
	/* --spacing-unit-below-section-header: 1; */


	/* goldilocks */

	--spacing-unit-below-main-header: 2.75;
	--spacing-unit-above-section-header: 2.75;
	--spacing-unit-below-section-header: .25;

	--common-spacing: 1.5;

	/* extended */

	--spacing-unit-below-main-header: 3;
	--spacing-unit-above-section-header: 3;
	--spacing-unit-below-section-header: 1;





	/* --spacing-unit-below-main-header: 3; */
	/* --spacing-unit-above-section-header: 3; */
	/* --spacing-unit-below-section-header: .5; */
	/* --common-spacing: 1; */



	/* box box */

	--spacing-unit-below-main-header: 2.5;
	--spacing-unit-above-section-header: 2.5;
	--spacing-unit-below-section-header: .5;
	--common-spacing: 1;



	/* box box spaced out 1.5 nuke*/

	--spacing-unit-below-main-header: 2.75;
	--spacing-unit-above-section-header: 2.75;
	--spacing-unit-below-section-header: .75;
	--spacing-unit-below-section-header: .25;
	--spacing-unit-below-section-header: 0;
	--spacing-unit-below-section-header: 2.75;

	/* ? */
	--common-spacing: 1.5;


	/* box box spaced out*/

	/* --spacing-unit-below-main-header: 2.5; */
	/* --spacing-unit-above-section-header: 2.5; */
	/* --spacing-unit-below-section-header: .25; */
	/* --common-spacing: 1.25; */




	/* box box spaced out 1.5 nuke tight inner OLD*/

	/* --spacing-unit-below-main-header: 2.75; */
	/* --spacing-unit-above-section-header: 2.75; */
	/* --spacing-unit-below-section-header: 0; */
	/* --spacing-unit-below-section-header: 2.75; */
	/* --common-spacing: 1.5; */
	/* --spacing-unit-above-first-carousel: 0 ; */
	/* --spacing-unit-below-last-carousel: 0; */

	/* box box spaced out stretch tight inner BAD*/

	/* --spacing-unit-above-first-carousel: 0 ; */
	/* --spacing-unit-below-main-header: 3; */
	/* --spacing-unit-above-section-header: 3; */
	/* --spacing-unit-below-section-header: 2; */
	/* --common-spacing: 1.75; */


	/* box box spaced out 1.5 nuke tight inner NEW SPACING*/

	--spacing-unit-above-first-carousel: 0;
	--common-spacing: 1.5;
	--spacing-unit-below-section-header: 2.5;

	--spacing-unit-below-main-header: 3;
	--spacing-unit-above-section-header: 3;
	--spacing-unit-below-last-carousel: 1.75;
	/* has one baked in unit .. 1.25? */



	/* box box spaced out NEW*/

	--spacing-unit-above-first-carousel: 0;
	--common-spacing: 1.25;
	--spacing-unit-below-section-header: 2.5;

	--spacing-unit-below-main-header: 2.5;
	--spacing-unit-above-section-header: 2.5;
	--spacing-unit-below-last-carousel: 1.25;
	/* has one baked in unit .. 1.25? */



	/* 1.5 NEW tight inner*/

	--common-spacing: 1.5;

	--spacing-unit-above-first-carousel: 0;
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1);
	/* --spacing-unit-below-section-header: 2.75; */


	--outer-spacing: 3;

	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25);





	--spacing-unit-above-first-carousel: 0;
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1);
	--spacing-unit-below-section-header: var(--outer-spacing);

	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	/* --spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25); */
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1);


	/* -------------- */
	/* -------------- */
	/* -------------- */

	
	/* 1.5 NEW tight inner*/

	/* --common-spacing: 1.5; */
	/* --outer-spacing: 3; */


	/* 1.5 NEW uniform */

	/* --common-spacing: 1.5; */
	/* --outer-spacing: 2.5; */


	/* something in the middle? */
	/* --common-spacing: 1.5; */
	/* --outer-spacing: 2.75; */


	/* 1.75 uniform GOOD WITH SUB DIV*/
	/* --common-spacing: 1.75; */
	--common-spacing: 1.75;
	--outer-spacing: 2.75;


/* 1.75 uniform FOR NO SUB DIV*/
	--common-spacing: 1.75;
	--common-spacing: 1.25;
	--common-spacing: 1.25;
	/* --common-spacing: 1; */
	--outer-spacing: 2.75;
	



/* tigher? FOR NO SUB DIV*/

--common-spacing: 1.25;
--common-spacing: 1;
--outer-spacing: 2.5;
/* --spacing-unit-below-section-header: calc(var(--outer-spacing) + .25); */
/* --spacing-unit-below-section-header: calc(var(--common-spacing) + 1); */

--outer-spacing: 3;
--common-spacing: 1;
/* --common-spacing: 1.25; */



/* --outer-spacing: 2.75; */
/* --common-spacing: .75; */


--outer-spacing: 2.75;
/* --outer-spacing: 3; */
--common-spacing: 1;


/* 3 stack */
--outer-spacing: 2.5;
--common-spacing: .75;
--common-spacing: .5;

/* settled on first */
--outer-spacing: 2.5;
--common-spacing: .5;


/* after walk with dad and brewing co -- just fiddling*/
/* --outer-spacing: 2.75; */

/* --outer-spacing: 2.25; */

/* --common-spacing: .75; */
/* --common-spacing: .5; */

/* --outer-spacing: 2.25; */
/* --common-spacing: .25; */

/* ? */
--outer-spacing: 2.75;
--common-spacing: .75;


/* ? 2 */
--outer-spacing: 2.5;
--common-spacing: .5;

/* --outer-spacing: 2.25; */
/* --common-spacing: .75; */


/* --outer-spacing: 2.25; */
/* --common-spacing: .5; */


/* --outer-spacing: 2.5; */
/* --common-spacing: .75; */


/* --outer-spacing: 2.75; */
/* --common-spacing: .75; */


/* looks pretty even? */

--outer-spacing: 2.75;
--common-spacing: .5;



/* --outer-spacing: 2.5; */
/* --common-spacing: .25;  */







	--test-color: red;


	--reg-section-header-visibility: auto;
	--mobile-section-header-visibility: none;



	--mobile-text-stack-gap: 6px;
	/* --mobile-text-stack-gap: 8px; */
	--mobile-text-stack-gap: 4px;

}

@media (min-width: 1921px) {
	:root {
		
		/* --frame-width: 47.5vw; */
		/* --frame-width: 50vw; */
		/* --frame-width: 40vw; */
		--frame-width: 42.5vw;
		--frame-width: 43vw;
		--frame-width: 43.5vw;

		/* original */
		/* --frame-width: 45vw; */

		--single-frame-width: calc(
				/* var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px + 10px + 10px + 72px  */
				var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px + 10px + 10px + (var(--mat-margin) * 2)
				/* frame width + pillar gap + thumb width + arrow container container width + indicator width + gap between indicator and thumb + 2x carousel padding */
			);
		font-size: var(--desktop-font-size);
		/* --frame-width: 2000px; */
	}
}

@media (max-width: 1024px) and (orientation: landscape) {

	/* @media (max-width: 1024px) and (orientation: landscape) { */
	:root {
		/* --frame-width: 70vw;  */
		/* --frame-width: 75vw;  */
		/* --frame-width: calc(95vw - var(--pillar-gap) - var(--thumb-width)); */
		/* --frame-width: calc(92vw - var(--pillar-gap) - var(--thumb-width)); */
		/* --frame-width: calc(94vw - var(--pillar-gap) - var(--thumb-width)); */
		/* --thumb-width: 128px; */
		/* --thumb-width: 10.5vw; */
		--thumb-width: 15vw;
		/* --frame-width: calc(97vw - var(--pillar-gap) - var(--thumb-width)); */
		/* --frame-width: calc(95vw - var(--pillar-gap) - var(--thumb-width)); */
		--frame-width: calc(93vw - var(--pillar-gap) - var(--thumb-width));
		/* --frame-width: calc(20vw - var(--pillar-gap) - var(--thumb-width)); */
		/* --single-frame-width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px) */
		--single-frame-width: 97vw;

		/* --pillar-gap:15px; */
		/* --pillar-gap:10px; */
		/* --pillar-gap:12px; */
		--pillar-gap: 1.25vw;
		--pillar-gap: 2vw;
		/* --pillar-gap:1.5vw; */
		/* --pillar-gap:20px; */

		--mobile-thumb-pos-ind-gap: 1.25vw;
		--mobile-thumb-pos-ind-gap: 1vw;
		/* --mobile-thumb-pos-ind-gap: .8vw; */
		--mobile-thumb-pos-ind-width: .5vw;
		/* --mobile-thumb-pos-ind-width: .4vw; */


		/* --frame-width: calc( var(--single-frame-width) - var(--pillar-gap) - var(--thumb-width) - 20px ); */
		/* --frame-width: calc( var(--single-frame-width) - var(--pillar-gap) - var(--thumb-width) - 20px ); */
		/* --frame-width: calc( var(--single-frame-width) - var(--pillar-gap) - var(--thumb-width) - var(--mobile-thumb-pos-ind-gap) - var(--mobile-thumb-pos-ind-width)); */
		--frame-width: 78.5vw;

		/* (97vw -3 - 15 - ) */

		font-size: var(--mobile-font-size);
		/* --frame-width: 500px;  */



		--vert-spacing-unit: 24px;
		
		--common-spacing: 1;
	--outer-spacing: 2;



	--spacing-unit-above-first-carousel: 0;
	/* --spacing-unit-below-section-header: calc(var(--common-spacing) + 1); */
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1.5);
	--spacing-unit-below-section-header: calc(var(--outer-spacing) - .25);
	--spacing-unit-below-section-header: var(--outer-spacing);
	/* --spacing-unit-below-section-header: var(--outer-spacing); */

	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25);
	--spacing-unit-below-last-carousel: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - .25);

	--spacing-unit-above-carousel: calc(var(--common-spacing) + 1);
	/* --spacing-unit-above-carousel: var(--common-spacing); */
	--spacing-unit-below-carousel: var(--common-spacing);
	/* --spacing-unit-below-carousel: calc(var(--common-spacing) - .25); */




	--common-spacing: 1;



	--outer-spacing: 2.25;
	--outer-spacing: 2;

	--test-color: orange;

	}
}

/* @media (max-width: 1920px) and (min-width: 1023px) { */
@media (max-width: 1920px) and (min-width: 1281px) {
	:root {
		--frame-width: 60vw;
		--frame-width: 56vw;


		--frame-width: 58vw;
		/* --frame-width: 60vw; */
		--thumb-width: 152px;
		--pillar-gap: 1.5vw;

		/* --thumb-width:12vw; */
		/* --thumb-width:11.5vw; */
		/* --thumb-width:11vw; */
		/* --thumb-width:12vw; */

		/* --single-frame-width: calc(
			var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px
		); */
		/* --frame-width: 65vw;  */
		/* font-size: var(--desktop-font-size); */
		font-size: var(--tablet-font-size);
		/* --frame-width: 1000px;  */

		/* --pillar-gap:1.5vw; */
		/* --pillar-gap:1vw; */
		/* --mat-margin: 24px; */




		--single-frame-width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px + 20px + (var(--mat-margin) * 2));

		--test-color: yellow;
	}
}


@media (max-width: 1280px) and (min-width: 1025px) {
	:root {

		--test-color: green;

		--frame-width: 60vw;
		--frame-width: 56vw;
		
		
		/* --frame-width: 58vw; */
		--frame-width: 60vw;
		/* --frame-width: 62.5vw; */
		/* --frame-width: 62vw; */
		--thumb-width: 152px;
		/* --pillar-gap:1.5vw; */
		--pillar-gap: 4vw;

		/* --thumb-width:12vw; */
		/* --thumb-width:11.5vw; */
		/* --thumb-width:11vw; */
		/* --thumb-width:12vw; */

		/* --single-frame-width: calc(
			var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px
		); */
		/* --frame-width: 65vw;  */
		/* font-size: var(--desktop-font-size); */
		font-size: var(--tablet-font-size);
		/* --frame-width: 1000px;  */

		/* --pillar-gap:1.5vw; */
		/* --pillar-gap:1vw; */
		/* --mat-margin: 24px; */




		--single-frame-width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 20px + (var(--mat-margin) * 2));
		
		/* --single-frame-width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 20px + (var(--mat-margin) * 2)); */







		/* --vert-spacing-unit: 32px;
		--common-spacing: 1;
	--outer-spacing: 2; */



	/* --spacing-unit-above-first-carousel: 0;
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1);
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1.5);
	--spacing-unit-below-section-header: calc(var(--outer-spacing) - .25);


	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25);
	--spacing-unit-below-last-carousel: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - .25);

	--spacing-unit-above-carousel: calc(var(--common-spacing) + 1);
	--spacing-unit-above-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: calc(var(--common-spacing) - .25); */




	--common-spacing: 1.5;
	--outer-spacing: 2.5;

	



	}
}

@media (max-width: 1024px) and (orientation: portrait) {

	/* @media (max-width: 1280px) and (orientation: portrait) { */
	:root {

		--test-color: blue;

		/* --frame-width: 80vw; */
		--frame-width: 100vw;
		--single-frame-width: 100vw;
		font-size: var(--tablet-font-size);
		/* --frame-width: 250px; */




	--spacing-unit-above-first-carousel: 0;
	/* --spacing-unit-below-section-header: calc(var(--common-spacing) + 1); */
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1.5);
	/* --spacing-unit-below-section-header: var(--common-spacing); */
	/* --spacing-unit-below-section-header: calc(var(--outer-spacing) - .25); */
	--spacing-unit-below-section-header: var(--outer-spacing);

	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25);
	--spacing-unit-below-last-carousel: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - .25);

	--spacing-unit-above-carousel: calc(var(--common-spacing) + 1);
	--spacing-unit-above-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: calc(var(--common-spacing) - .25);

	


		/* --vert-spacing-unit:24px; */
		/* --common-spacing: 1.25; */
		--common-spacing: 2.25;
		--common-spacing: 1.75;
		--common-spacing: 2;
		/* --common-spacing: 1.5; */
		--outer-spacing: 2.25;
		--outer-spacing: 1.75;
		--outer-spacing: 2;

		/* --outer-spacing: 1.25; */
		/* --outer-spacing: 1.75; */



	
		--reg-section-header-visibility: none;
		--mobile-section-header-visibility: auto;

	}
}

@media (max-width: 860px) and (orientation: portrait) {
	:root {
		/* --frame-width: 80vw; */
		--frame-width: 100vw;
		--single-frame-width: 100vw;
		font-size: var(--mobile-font-size);
		/* --frame-width: 250px; */


		--vert-spacing-unit: 24px;
		/* --vert-spacing-unit: 32px; */
		--common-spacing: 1;
	--outer-spacing: 2;



	--spacing-unit-above-first-carousel: 0;
	/* --spacing-unit-below-section-header: calc(var(--common-spacing) + 1); */
	--spacing-unit-below-section-header: calc(var(--common-spacing) + 1.5);
	--spacing-unit-below-section-header: calc(var(--outer-spacing) - .25);
	/* --spacing-unit-below-section-header: var(--outer-spacing); */

	--spacing-unit-below-main-header: var(--outer-spacing);
	--spacing-unit-above-section-header: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - 1.25);
	--spacing-unit-below-last-carousel: var(--outer-spacing);
	--spacing-unit-below-last-carousel: calc(var(--outer-spacing) - .25);

	--spacing-unit-above-carousel: calc(var(--common-spacing) + 1);
	--spacing-unit-above-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: var(--common-spacing);
	--spacing-unit-below-carousel: calc(var(--common-spacing) - .25);



	--common-spacing: 1.25;
	--common-spacing: 1;
	--common-spacing: 1.5;
	--common-spacing: 1.75;
	--common-spacing: 2;
	/* --common-spacing: 2.25; */
	/* --common-spacing: .75; */
	--outer-spacing: 2.75;
	--outer-spacing: 2.5;
	/* --outer-spacing: 3; */

	--common-spacing: 2.25;
	/* --common-spacing: 1.5; */
	/* for without sub-div */
	--outer-spacing: 2.25;

	
    /* qk late */
    /* qk late */
	--common-spacing: 1.25;
	--common-spacing: 1.5;
	/* --common-spacing: 2.25; */
	/* --common-spacing: 1.5; */
	/* for without sub-div */
	--outer-spacing: 2.25;
	--outer-spacing: 2.5;
	/* --outer-spacing: 3; */
    /* qk late */
    /* qk late */

	
	--reg-section-header-visibility: none;
	--mobile-section-header-visibility: auto;


	--slow-scroll-speed: 525ms cubic-bezier(.27,.76,.51,.99);
	--slow-scroll-speed: 300ms cubic-bezier(.27,.76,.51,.99);

	}
}














/* @media (max-width: 1024px) and (orientation: portrait) {
    :root {
        --frame-width: 100vw;
		font-size: var(--mobile-font-size);
	}
} */

.outer {
	/* display: flex;
  width: 100%;

justify-content: center;
align-items: center;
background-color: cornflowerblue; */

	/* border: rgb(226, 226, 226) dashed 40px; */

}

.App {
	font-family: 'Inter', sans-serif;
	text-align: center;
	/* font-weight: 300; */
	/* background-color: rgb(219, 255, 255); */
	/* height: 200vh; */
	/* padding-bottom: 1000px; */
	/* margin-bottom: 1000px; */
	/* background-color: #F5F5F5; */
	/* background-color: rgb(48, 48, 48);
  color: rgb(214, 214, 214); */
	/* transition: background-color 0.3s ease; */
	/* background-color: #363636; */
	/* color: lightgrey; */

	/* transition: background-color 400ms ease; */
	/* transition: background-color 300ms ease; */
	/* transition: background-color 2000ms ease; */
	/* transition: background-color 850ms ; */

	/* new layout styling */

	display: flex;

	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
	/* flex: 1 0 0; */

	background-color: white;

	/* border: 30px rgb(200, 200, 200) solid; */

}

.disable-interactions {
	pointer-events: none;
}

.bold {
	font-size: large;
	font-weight: 400;
}

h1 {
	font-family: sans-serif;
	font-size: 60px;
	font-weight: bold;
	color: aqua;

	/* -webkit-text-stroke: black;
  -webkit-text-stroke-width: 5px; */

	paint-order: stroke;
	
}

p {
	/* color: blueviolet;
  font-size: 24px;
  position: relative; */
 
}

.selectable-text{
	cursor: text;
	user-select:text;
}

p:after {
	content: attr(data-text);

	position: absolute;
	left: 0;
	z-index: -1;

	/* visible stroke is 10px because of alignment */
	-webkit-text-stroke: 5px blue;

	/* is sized relative to h1 */
	font-size: 1em;
}

.italic-span {
	font-style: italic;
}

img.signature {
	/* width: 40vw; */
	/* max-width: var(--frame-width); */
	/* max-height: 80vh; */
	/* jumbo ^*/
	/* max-height: 65vh; good */
	/* max-height: 75vh; */
	/* max-height: 80vh; */
	user-select: none;
	pointer-events: none;

	width: 150px;
	/* width: 105px; */
	/* width: 100px; */
	/* width: 95px; */
	/* width: 83px; */
	/* width: 69px; */
	width: 72px;
	/* width: 80px; */
	/* width: 50px; */
	/* width: 300px; */
	/* width: 250px; */
	/* top: 50%;
	left: 50%; */

	/* transform: rotate(-15deg); */
	/* padding-top: 15px; */
	/* padding-top: 30px; */
	/* padding-top: 150px; */

	/* top picked */
	/* padding-bottom: 125px;
	padding-bottom: 130px;
	padding-right: 10px; */

	/* padding-bottom: 110px; */
	/* padding-bottom: 112px; */

	/* padding-top: 128px; */
	/* padding-left: 15px; */
	/* padding-left: 23px; */
	/* padding-left: 21px; */
	/* padding-bottom: 110px; */

	/* padding-left: 250px; */
	/* top: 60%; */
	z-index: -1;
	/* opacity: 80%; */
	/* opacity: 50%; */
	/* opacity: 15%; */
	/* opacity: 5%; */
	/* opacity: 10%; */
	/* opacity: 13%; */
	opacity: 18%;
	/* width: 83px; */
	/* 
	width: 83px;
	transform: rotate(7deg);
	padding-top: 119px;
padding-left: 63px; */
	/* height: auto; */
	/* border-radius: 4px/4px; */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.157); */
	/* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1); */
	/* box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.15); */

	position: absolute;
	padding-bottom: 115px;
	padding-left: 18px;
}

.return-arrow-grid {
	display: grid;
	grid-template-columns: auto 0px;
}

.signature-div {
	/* background-color: yellow; */

	width: 100%;
	margin-top: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	user-select: none;
	font-weight: normal;
	flex-direction: column;
	gap: 32px;
	height: 200px;


	height: auto;
	margin-top: 128px;
	margin-bottom: 64px;
	/* height: 128px; */
	scale: .9;

	/* margin-bottom: 72px; */
	margin-bottom: 48px;
}

.copyright-tag {
	padding: 8px 12px 8px 12px;
	/* color: var(--main-grey); */
	/* user-select: all; */
}


@media (max-width: 1024px) and (orientation: portrait) {
	.signature-div {
		width: 100%;
		/* height: 200px; */
		/* height: 64px; */
		height: auto;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 2;
		user-select: none;
		font-weight: normal;
		flex-direction: column;
		gap: 32px;
		/* margin-bottom: 64px; */
		/* margin-bottom: 32px; */

		margin-top: 0px;
		margin-top: 8px;
		/* margin-top: 32px; */
		/* margin-top: 16px; */
		/* margin-bottom: 64px; */
		/* margin-bottom: 72px; */
		margin-bottom: 48px;
		/* margin-bottom: 32px; */
		/* margin-bottom: 36px; */
		/* margin-bottom: 24px; */
		/* margin-bottom: 64px; */
		/* margin-top: 16px; */
		/* margin-top: 64px; */
		/* background-color: yellow; */

		/* margin-top: 16px;
		margin-bottom: 32px; */
		/* margin-top: 16px;
		margin-bottom: 48px; */
	}


	.copyright-tag {
		/* font-size: 12px; */
		padding: 5px 10px 5px 10px;
	}
}


.buffer-div {
	/* height: calc(.1 * var(--frame-height)); */
	/* height: calc(.4 * var(--frame-height)); */
	height: calc(.6 * var(--frame-height));
	/* height: calc(.2 * var(--frame-height)); */
}

html{
	/* scroll-snap-type: mandatory; */
	/* scroll-snap-type: y mandatory; */
	/* scroll-snap-type: y proximity; */
}