.education1 {
  position: absolute;
  top: 0;
  left: 0;
}
.education {
  width: 50px;
  height: 12px;
  text-align: center;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
  font-family: var(--font-inter);
}
