:root {
	--heading-font-size: 18px;

}



.main-heading-container {
	/* align-items: center; */
	/* background-color: lightgoldenrodyellow; */
	display: inline-flex;
	margin-top: 36px;
	margin-bottom: 36px;
	justify-content: center;
	/* flex-direction: column; */
	/* position: relative; */
}

.heading {
	display: flex;
	/* background-color: forestgreen; */
	justify-content: space-between;
	align-items: center;
	/* width: 80vw; */
	/* width: 50vw; */
	/* width: calc(var(--frame-width)+200px); */
	/* width: calc(var(--frame-width) + 4vw + 165px + 96px);  */
	/* width: calc(var(--frame-width) + 4vw + 165px + 96px);  */
	width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px);

	width: var(--single-frame-width);
	/* carousel frame width  + gap + thumb + arrow container width */

}

.heading-locked {
	margin-bottom: 24px;
}


@media (max-width: 1024px) and (orientation: portrait) {
	.heading {
		/* width: calc(var(--single-frame-width) - 48px); */
		/* Add other styles specific to landscape orientation and max-width: 1024px */

		/* width: 80vw; */
		/* width: calc(90vw - 48px); */
		/* width: calc(var(--single-frame-width) - 48px); */
		width: calc(var(--single-frame-width) - 96px);
	}
}

/* @media (max-width: 1024px) { */
@media (max-width: 768px) {

	.heading {
		/* width: 90%; */
		/* width: 90vw; */
		/* width: 80vw; */
		width: calc(var(--single-frame-width) - 48px);


	}

	.main-heading-container {
		/* margin-bottom: 8px; */
		/* margin-bottom: 24px; */
		margin-bottom: 32px;
	}

}


@media (max-width: 1024px) and (orientation: landscape) {
	.heading {
		width: calc(var(--single-frame-width) - 48px);
		/* Add other styles specific to landscape orientation and max-width: 1024px */
	}
}






.business-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 14px;

	/* color: #000; */
	font-family: Inter;
	/* font-size: 28.11px; */
	font-size: var(--heading-font-size);
	font-style: normal;
	font-weight: 400;
	line-height: normal;

}

@media (max-width: 1024px) and (orientation: portrait) {
	.business-card {

		/* font-size: var(--mobile-font-size); */
		font-size: calc(1.25 * var(--mobile-font-size));

	}
}

.resume-div {
	/* font-size: 28.11px; */
	width: 100px;
	/* background-color: cyan; */

	display: flex;
	justify-content: flex-end;
	align-items: center;

}

.resume-div-text {
	color: #5BB0FF;
	font-family: Inter;
	font-size: var(--heading-font-size);
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	user-select: none;

	/* transition: all 250ms; */
	/* transition: all 1000ms ease-in-out; */
	/* transition: all 1000ms; */

	/* transition: all 500ms; */
}

@media (max-width: 1024px) and (orientation: portrait) {
	.resume-div {

		font-size: calc(1.25 * var(--mobile-font-size));
	}
}



.resume-div-text:hover {
	color: #7cc0ff;
	/* transition: 0ms; */
	/* font-weight: 900; */

	/* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
	text-shadow: none;
	/* outline:1px solid #7cc0ff; */
	/* padding: 4px; */
	/* border-radius: 4px; */
	/* cursor: none; */
	/* text-decoration: underline; */
	/* text-decoration-thickness: 1px;  */
	/* text-underline-offset: 4px;  */
}



@media (max-width: 1024px) {



	.resume-div-text:hover {
		color: #5BB0FF;
		color: #4484bf;
	}


	.resume-div-text:hover {
		color: #5BB0FF;

	}

}





.resume-div-text-dark {
	/* color: #ffffff; */
	/* color: black; */
	/* color: #cde7ff; */
	/* font-weight: bold; */
	/* font-weight: 200; */
	/* stroke: 1px solid white; */
	/* color: #0b6ec9; */
	/* color: #1a81e2; */
	/* color: black; */
	/* color: #909090; */
	/* color: #ffffff; */

	/* color: #4194e2; */
	/* color: #50a2ef; */
	/* color: #5fb2ff; */
	/* color:white; */

	/* color: #429ef5; */
	/* color: #4ea7fa; */

	/* color: #4484bf;   */

	/* color: #6393bf; */

	/* color: #81a1bf; */


	color: #999999;

	/* color: #888888; */
	color: hsl(0, 0%, 46%);
	color: #757575;

	color: #b2b2b2;
	color: #656565;
	color: #8c8c8c;
	color: hsl(0, 0%, 55%);
	color: hsl(0, 0%, 50%);
	/* color: var(--main-grey); */
	/* color: #5BB0FF; */

	/* color: #4484bf; */




	/* color: hsl(0, 0%, 56%); */
	/* maingrey */

	/* color: white; */
	/* color: hsl(0, 0%, 100%); */
	/* color: hsl(0, 0%, 90%); */
	/* color: #e5f2ff; */
	/* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
	/* text-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0.08); */
	/* text-shadow: 0px 3.5px 1.4px rgba(0, 0, 0, 0.2); */


	/* color: #8f8f8f; */



	transition: all 500ms;
	/* transition: all 250ms; */

	/* ?????? */

	/* color: #8c8c8c; */
	/* color: #5d90bf; */
	/* color: #ffb65b; */


	position: relative;

	z-index: 2;





	/* color: #4789C7; */


	/* 77 */
	/* color: #4687C4; */
	/* 77 */


	/* 74 */
	/* color: #4382BD; */
	/* 74 */

	/* 79 */
	/* color: #488BC9; */
	/* 79 */


	/* 78 */
	color: #4789C7;
	/* color: #5BB0FF; */
	/* 78 */


}

.resume-div-text-dark:hover {


	/* 78 */
	color: #6196C7;
	/* 78 */


	/* 77 */
	/* color: #5F94C4; */
	/* 77 */


	/* 74 */
	/* color: #5C8EBD; */
	/* 74 */

	/* 79 */
	/* color: #6297C9; */
	/* 79 */











	/* color: #daedff; */
	/* color: #5BB0FF; */
	/* color: #cde7ff; */
	/* color: var(--activity-blue); */

	/* color: #5ea2e2; */

	/* color: #5d90bf; */





	transition: 0ms all;
	/* text-decoration: underline; */



	/* scale: 200%; */
	/* color: red; */
	/* color: #ffbe7c; */

}

/* .resume-div-text-fading{
	transition: 1000ms all;
	color: green;
	scale: 50%;
} */




/* .resume-div-text-dark::before {

		-webkit-text-stroke-width: 2px;

		
		-webkit-text-stroke-color: hsl(0, 0%, 56%);

		content: attr(text-content);

		position: absolute;
		z-index: -1;

	}

	.resume-div-text-dark:hover::before{

		opacity: 0;
	} */