.inch {
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 4px);
}
.inch-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-11xs);
  background-color: var(--color-lightskyblue);
  overflow: hidden;
}
.property-116-inch {
  width: 100%;
  height: 12px;
  opacity: 0;
  text-align: center;
  font-size: var(--font-size-8xs);
  color: var(--color-black);
  font-family: var(--font-inter);
}
