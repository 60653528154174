.component-child {
  position: absolute;
  height: 66.44%;
  width: 87.18%;
  top: 16.78%;
  right: 6.41%;
  bottom: 16.78%;
  left: 6.41%;
}
.rectangle-parent {
  width: 595px;
  height: 8px;
}
