:root {
	/* --view-width: calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px); */
	/* --view-width: 55vw; */
}

.Test {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}


.single-carousel-styling {
	user-select: none;

	flex-direction: row;
	display: flex;
	gap: 16px;

	overflow: hidden;

	margin: auto;

	border-radius: 10px/10px;

	background-clip: content-box;

	scroll-behavior: smooth;

	background: none;

	transition: ease-in-out 350ms, border 0ms, outline 0ms;

	background-color: var(--carousel-ground-color);
	/* background-color: var(--test-color); */


	/* padding: var(--mat-margin); */
	padding: 0px;

	outline: var(--stroke-color) 1px solid;
border: none;

	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.06);

}



.single-carousel {
	/* width: calc( var(--single-frame-width) - ( 2 * var(--mat-margin) ) ); */

	/* width:var(--single-frame-width); */
	width: calc(var(--single-frame-width) - var(--mat-margin) * 2);
	/* width:calc( var(--single-frame-width) - var(--mat-margin) * 8 ); */
	cursor: auto;

	transition: 0ms all;



}



.single-carousel-alt {
	width: var(--single-frame-width);

	/* background-color:hsl(0, 0%, 93%); */
	background-color: var(--carousel-ground-color);

	outline: var(--stroke-color) 1px solid;

	cursor: auto;

	border-radius: 10px/10px;

}


@media (max-width: 1024px) and (orientation: portrait) {

	.single-carousel-styling {
		/* padding: none; */
		padding: 0px;
	}

	.single-carousel {
		border-radius: 0px;
		/* border: 1px solid rgba(0, 0, 0, 0.1); */
		border-top: var(--stroke-color) 1px solid ;
		border-bottom: var(--stroke-color) 1px solid ;
		border-left: none;

		border-right: none;
		width: var(--single-frame-width);
		padding: 0px;
		outline: none;
		/* border: none; */
		/* background: linear-gradient(
		   135deg,
		   rgba(0, 0, 0, 0.1) 0%,
		   rgba(0, 0, 0, 0) 100%
	   ); */
	}
}

@media (max-width: 1024px) and (orientation: landscape) {
	.single-carousel {
		/* border-radius: 0px; */
		/* border: 1px solid rgba(0, 0, 0, 0.1); */
		/* border-left: none; */
		/* border-right: none; */
		width: var(--single-frame-width);
		/* border: none; */
		/* background: linear-gradient(
		   135deg,
		   rgba(0, 0, 0, 0.1) 0%,
		   rgba(0, 0, 0, 0) 100%
	   ); */
	   padding: none;
	}
}



.dragging {
	cursor: grabbing;
	scroll-behavior: auto;
	transition: auto;
}

.instant {
	transition: auto;
	scroll-behavior: auto;

}

/* rename quick-selector */
.instant2 {
	/* transition: 250ms; */
	/* transition: 350ms; */
	transition: 400ms;
	/* transition: 500ms; */
	scroll-behavior: auto;

}




/* .left-side-single {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

@media screen and (max-width: 1024px) {
	.left-side-single {
		gap: 0px;
	}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	.left-side-single {
		width: var(--frame-width);
	}
} */


/* from carousel item  css vvvvv*/

/* img.carousel-img {


    max-width: 60vw;
    max-height: 80vh;
    user-select: none;

}

.img-container {


    user-select: none;
    width: 60vw; */