.main-nav-container{
    /* padding-top: 5vh; */
    /* padding-bottom: 5vh; */
    /* margin-bottom: 7.5vh; */
    display: flex;
justify-content: center;
align-items: center;
gap: 12px;
/* background-color: antiquewhite; */


/* padding: 64px 0px 64px 0px */
/* margin: 64px 0px 64px 0px */
/* margin: 72px 0px 72px 0px */
/* margin: 72px 0px 72px 0px */
margin-bottom: 96px;
margin-bottom: 72px;
margin-bottom: calc( var(--vert-spacing-unit) * var(--spacing-unit-below-main-header) );
/* margin-top: 36px; */
margin-top: 48px;
/* margin-top: 72px; */

}


@media (max-width: 768px) and (orientation: portrait) {
    .main-nav-container{
        /* gap:1%; */
        flex-direction: column;
        gap: 16px;

        /* margin-bottom: 24px; */
        /* margin-bottom: 48px; */
        /* margin-bottom: 64px; */

/* 
        display: flex;
flex-direction: column;
align-items: flex-start; */
}
}