.pw-input:focus {
	/* outline-color: red;  */
    /* box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.08);  */
	/* outline-color: var(--activity-blue);  */
}


.unlocker-div{


}



@media (max-width: 1024px) and (orientation: portrait) {

	.unlocker-div{

		margin-bottom: 48px;
		/* margin-bottom: 64px; */
		/* margin-bottom: 72px; */
		margin-bottom: 80px;
		/* margin-bottom: 88px; */
		/* margin-bottom:console.log(); 256px; */
		/* margin-bottom: 12px; */

	}
	

}