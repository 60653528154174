.chainscapegameslogo2-icon {
  position: absolute;
  top: calc(50% - 9.37px);
  right: 0.16px;
  width: 69.63px;
  height: 18.51px;
  object-fit: cover;
  display: none;
}
.logo {
  position: absolute;
  top: -2.98px;
  left: 45.68px;
  line-height: 136.02%;
  display: none;
  text-shadow: 0 1.6975644826889038px 1.7px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1.7px #000;
}
.logo-chainscape-games-pixel-ar {
  position: absolute;
  top: 0;
  left: 82.68px;
  width: 47.67px;
  height: 29.59px;
  object-fit: cover;
}
.chainscapegameslogo2-icon1,
.logo-chainscape-games-pixel-ar1 {
  position: absolute;
  top: 6.08px;
  left: 26.68px;
  width: 105px;
  height: 12px;
  object-fit: cover;
  display: none;
}
.chainscapegameslogo2-icon1 {
  top: -0.13px;
  left: 31.68px;
  width: 99px;
  height: 24px;
}

.image-71-icon,
.ld-icon,
.ludere-orig-alt {
  position: absolute;
  top: 0.08px;
  left: 63.68px;
  width: 67px;
  height: 24px;
  object-fit: cover;
  display: none;
}


.ludere-orig-icon {
  position: absolute;
  top: 0.08px;
  left: 63.68px;
  width: 67px;
  height: 24px;
  object-fit: cover;
  /* display: none; */
}


/* .image-56-icon {
  position: absolute;
  top: 0.08px;
  left: 63.68px;
  width: 67px;
  height: 24px;
  object-fit: cover;
} */

.image-56-icon {
  position: absolute;
  top: 0px;
  left: 63.68px;
  width: 67px;
  height: 24px;
  object-fit: cover;
  /* display: none; */
}


.image-71-icon,
.ld-icon,
.ludere-orig-alt,
.ludere-orig-icon {
  top: 4.08px;
  height: 15px;
}
.ld-icon,
.ludere-orig-alt,
.ludere-orig-icon {
  top: 0.19px;
  left: 98.68px;
  width: 20px;
  height: 24px;
}
.ludere-orig-alt,
.ludere-orig-icon {
  top: 13.95px;
  left: 68px;
  width: 62.68px;
  height: 9.79px;
}
.ludere-orig-icon {
  top: 6.19px;
  left: 54.68px;
  width: 76px;
  height: 11.88px;
}
.chainscapegameslogo2-parent {
  position: relative;
  width: 131px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.chainscapegameslogo2-icon2 {
  position: absolute;
  top: calc(50% - 8.87px);
  right: 0.16px;
  width: 69.63px;
  height: 18.51px;
  object-fit: cover;
  display: none;
}
.logo1 {
  position: absolute;
  top: 1.11px;
  left: 50.68px;
  line-height: 136.02%;
  text-shadow: 0 1.2342857122421265px 1.23px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 1.2px #000;
}
.chainscapegameslogo2-group {
  position: relative;
  background-color: var(--color-lavenderblush);
  width: 95px;
  height: 19px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  /* font-size: 12.34px; */
}
.inch2 {
  position: absolute;
  top: calc(50% - 3.25px);
  left: calc(50% - 5px);
}
.inch-frame {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-11xs);
  background-color: var(--color-yellow);
  overflow: hidden;
}
.component-1 {
  align-self: stretch;
  position: relative;
  height: 4.5px;
  opacity: 0;
  text-align: center;
  /* font-size: var(--font-size-8xs); */
  color: var(--color-black);
}
.p {
  line-height: 136.02%;
}
.chainscape-games,
.p {
  margin: 0;
}
.chainscape-games-web3-container {
  align-self: stretch;
  position: relative;
  /* font-size: var(--font-size-3xs); */
  color: var(--color-black);
  text-align: right;
}
.frame-parent {
  width: 106px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.spearheaded-the-aesthetic-for {
  margin-bottom: 0;
}
.led-multinational-art-departme {
  margin: 0;
  padding-left: var(--padding-smi);
}
.art-director-and-container {
  flex: 1;
  position: relative;
  /* font-size: var(--font-inherit); */
  /* font-size: .9em; */
  line-height: 136.02%;
  color: var(--color-black);
}
.exp-content-component {
  width: 504px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  text-align: left;
  /* font-size: 16.98px; */
  color: var(--color-white);
  font-family: var(--font-inter);
}
