:root {
	/* --view-width: 45vw; */
	/* --view-width: 55vw; */

	/* --carousel-activity-outline: 3px var(--stroke-color) solid; */
	--carousel-activity-outline-color: black;
	--carousel-bg-color: black;
	--carousel-activity-stroke-weight: 3px;
	/* --carousel-activity-outline-color: var(--main-grey); */
	/* --carousel-activity-outline-color: var(--stroke-color); */
	/* --carousel-activity-outline: 3px var(--carousel-activity-outline-color) solid; */
	--carousel-activity-outline: var(--carousel-activity-stroke-weight)
		var(--carousel-activity-outline-color) solid;
	--carousel-activity-outline-active: var(--carousel-activity-stroke-weight)
		var(--carousel-activity-outline-color-active) solid;
	/* --carousel-activity-outline-color: hsl(0, 0%, 92%); */
	/* --carousel-activity-outline: 8px var(--carousel-activity-outline-color) solid; */
	/* --carousel-activity-outline: 6px var(--carousel-activity-outline-color) solid; */

	/* --carousel-activity-outline-color: var(--main-grey); */
	/* --carousel-bg-color: hsl(0, 0%, 92%); */
	/* --carousel-activity-outline-color: var(--main-grey); */
	--carousel-activity-outline-color: var(--active-bg);
	--carousel-activity-outline-color-active: var(--active-bg-mouseover);

	/* --carousel-activity-outline-color: var(--active-bg-mouseover);
	--carousel-activity-outline-color-active: var(--active-bg); */

	/* --carousel-activity-outline-color-active: red; */
	/* --carousel-activity-outline-color: hsl(0, 0%, 92%); */
	/* --carousel-bg-color: hsl(0, 0%, 92%); */

	--carousel-activity-outline-color: black;
	/* --carousel-activity-outline-color-active: hsl(0, 0%, 50%); */
	--carousel-activity-outline-color-active: hsl(0, 0%, 55%);
	/* --carousel-activity-outline-color-active: hsl(0, 0%, 75%); */
	--carousel-bg-color: black;

	/* --carousel-bg-color: hsl(0, 0%, 92%);
	--carousel-activity-outline-color-active: hsl(0, 0%, 80%);
	--carousel-activity-outline-color: hsl(0, 0%, 60%); */

	/* --carousel-bg-color: hsl(0, 0%, 0%);
	--carousel-activity-outline-color: hsl(0, 0%, 60%);
	--carousel-activity-outline-color-active: hsl(0, 0%, 80%); */

	--carousel-bg-color: hsl(0, 0%, 92%);
	--carousel-activity-outline-color: hsl(0, 0%, 60%);
	--carousel-activity-outline-color-active: hsl(0, 0%, 80%);

	/* --carousel-activity-outline-color: hsl(0, 0%, 95%); */
	/* --carousel-activity-outline-color-active: hsl(0, 0%, 90%); */
	/* --carousel-activity-stroke-weight: 7px; */
	/* --carousel-activity-stroke-weight: 15px; */

	/* --carousel-activity-outline-color-active: hsl(204, 75%, 87%); */
	/* --slow-scroll-speed: 450ms ease-out;
	--slow-scroll-speed: 400ms ease-out;
	--slow-scroll-speed: 350ms ease-out;

	--slow-scroll-speed: 600ms cubic-bezier(0.19, 1, 0.22, 1); */
}

.Test {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.Carousel {
	user-select: none;

	flex-direction: row;
	display: flex;
	gap: 16px;
	/* padding-left: 64px;  -- for bounce*/
	/* gap: 64px; */
	overflow: hidden;
	/* width: 50vw; */
	/* width: var(--view-width); */
	width: var(--frame-width);
	/* try 45, try 50, try 55 */
	/* width: 576px;  -- bounce?*/
	margin: auto;
	/* background-color: #282c34; */
	/* border-radius: 16px/16px; */
	border-radius: 10px/10px;
	/* border: 1px solid rgba(0, 0, 0, 0.25); */
	border: 1px solid var(--stroke-color);
	/* border: 1px solid rgba(0, 0, 0, 0.15); */
	/* border: 1px solid rgba(255, 255, 255, 0.25); */
	background-clip: content-box;
	cursor: grab;
	scroll-behavior: smooth;
	/* transition: ease-in-out 350ms; */
	/* transition: ease-in-out 350ms, border 0ms, outline 0ms; */
	transition: ease-in-out 350ms, border 0ms;
	transition: ease-in-out 500ms, border 0ms;
	transition: ease-out 500ms, border 0ms;
	transition: var(--slow-scroll-speed), border 0ms;
	/* transition: ease-out 350ms, border 0ms; */
	/* transition: 500ms, border 0ms; */

	/* background: linear-gradient(
		-45deg,
		rgba(0, 0, 0, 0.07) 0%,
		rgba(0, 0, 0, 0) 100%
	); */
	/* background-color: rgb(232, 232, 232); */
	/* background-color: rgb(237, 237, 237); */
	/* background: none;
	background-color: rgb(223, 223, 223);
	border: none; */
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)); */
	/* box-shadow: 3px 5px 2px 0px rgba(0, 0, 0, 0.15); */
	background: linear-gradient(
		135deg,
		rgba(0, 0, 0, 0.07) 0%,
		rgba(255, 255, 255, 0.166) 100%
	);
	/* background: linear-gradient(
		135deg,
		rgb(214, 214, 214) 0%,
		rgb(227, 227, 227) 100%
	); */

	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.07); */
	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.15); */
	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.09); */
	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.09); */
	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.04); */
	/* box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.05); */

	box-shadow: inset 0px 4px 5px 0px rgba(0, 0, 0, 0.04);

	background: none;

	/* overscroll-behavior: contain; */
	/* padding: 40px; */
	/* padding: 16px; */
	padding: 12px;
	/* padding: 16px; */
	padding: 24px;

	/* padding: 36px; */

	/* padding: 32px; */
	/* padding: 20px; */
	/* overscroll-behavior:auto;
	border: red 4px solid; */
	/* background-color: var(--active-bg); */
	/* background: linear-gradient(
		180deg,
		 rgba(0, 0, 0, 0.07) 0%,
		rgba(0, 0, 0, 0) 75%
	); */

	/* background: none; */
	/* background-color: rgb(210, 210, 210); */
	/* background-color: hsl(0, 0%, 82%); */
	/* background-color: rgb(235, 235, 235); */
	/* background-color: hsl(0, 0%, 92%); */
	/* background-color: hsl(0, 0%, 90%); */
	/* background-color: rgb(0, 0, 0); */
	/* background-color: var(--carousel-activity-outline-color); */
	/* background-color: var(--carousel-bg-color); */
	/* background-color: hsl(0, 0%, 82%); */
	/* background-color: hsl(0, 0%, 50%); */
	/* background-color: hsl(0, 0%, 92%); */
	/* background-color: hsl(0, 0%, 25%); */
	/* background-color: hsl(0, 0%, 60%); */
	/* background-color: hsl(0, 0%, 50%); */
	/* background-color: hsl(0, 0%, 40%); */
	/* background-color: hsl(0, 0%, 92%); */
	/* background-color: hsl(0, 0%, 93%); */
	background-color: var(--carousel-ground-color);

	/* background-color: hsl(0, 0%, 82%); */
	/* background-color: hsl(220, 5%, 40%); */
	/* background-color: #008080; */
	/* background-color:  #0a7272; */
	/* background-color: #5c5c5c; */
	/* background-color: hsl(0, 0%, 92%); */
	/* background-color: #d2d2d2; */
	/* background-color: rgb(127, 127, 127); */

	/* background-color: rgb(0, 0, 0); */
	/* border: black 4px solid; */
	/* background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 10px/10px;
  border: 4px solid transparent; */

	/* background: linear-gradient(rgb(210, 210, 210), rgb(210, 210, 210)) padding-box,
  linear-gradient(to right, rgb(0, 0, 0), rgb(255, 255, 255)) border-box;
border-radius: 10px/10px;
border: 4px solid transparent; */

	/* background: linear-gradient(rgb(190, 190, 190), rgb(210, 210, 210)) padding-box,
linear-gradient(to bottom, rgb(225, 225, 225), rgb(245, 245, 245)) border-box;
border-radius: 10px/10px;
border: 4px solid transparent; */

	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)); */
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.4)); */
	/* border: none; */
	/* border: #cdcdcd 1px solid; */

	box-shadow: none;
	/* background-color: hsl(0, 0%, 82%); */
	/* background-color: hsl(0, 0%, 90%); */

	background: linear-gradient(
		135deg,
		rgba(0, 0, 0, 0.04) 0%,
		rgba(255, 255, 255, 0) 100%
	);





	/* background: linear-gradient(
		-45deg,
		rgba(0, 0, 0, 0.05) 0%,
		rgba(255, 255, 255, 0) 100%
	);

	background-color: hsl(0, 0%, 96%); */

	background: none;
	/* background-color: hsl(0, 0%, 92%); */
	background-color: var(--carousel-ground-color);
	/* background-color: white; */
	/* background-color: var(--test-color); */
	box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.04);


	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.06);

/* box-shadow: none; */


	/* padding: 32px; */
	/* padding: 36px; */
	padding: var(--mat-margin);

	border: none;
	outline: var(--stroke-color) 1px solid;
	/* outline-offset: 2px; */
	/* outline-offset: 4px; */
	/* outline-offset: 8px; */
	/* box-shadow: none; */
	/* outline-offset: 2px; */
	/* outline: none; */
	/* outline-offset: -8px; */
	/* border-radius: 0px; */
	/* border-radius: 2px/2px; */
	/* border-radius: 8px/8px; */
	/* border-radius: 14px/14px; */
	/* outline: none; */
	/* outline: hsl(0, 0%, 55%) 1px solid; */
	/* box-shadow: none; */
	/* filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15)) */
	/* box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.07); */

	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08); */
	

	/* padding: 0px; */
	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.12); */
	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08); */
	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.075); */
	
	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07); */


		/* to match chiclet? */

		/* transition: all 5ms; */
	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.13); */
	/* border-radius: 2px/2px; */
}


.Carousel:hover {
	/* outline: var(--carousel-activity-outline); */
	/* border: 1px solid var(--carousel-activity-outline-color); */
	/* box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.08); */
	/* outline-offset: 0px; */
	/* outline: var(--carousel-ground-color) 3px solid; */
	/* transition: outline 0ms; */
}

.Carousel:active{
	/* background-color: hsl(0, 0%, 88%); */
	/* background-color: hsl(0, 0%, 80%); */
	/* background-color: hsl(0, 0%, 100%); */
	/* background-color: hsl(0, 0%, 96%); */
	/* box-shadow: inset 0px 4px 16px rgba(0, 0, 0, 0.3); */
	/* background-color: hsl(0, 0%, 0%); */



	/* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.00); */
	/* outline: solid var(--highlight-stroke) var(--highlight-color); */
	/* padding-left: 128px; */
	/* padding-right: 128px; */
	/* transition: all 300ms; */
	/* padding-left: 256px; */
	/* padding-right: 256px; */

	/* scale: 75%; */
	/* scale: 50%; */
}

.Carousel:active {
	/* outline: var(--carousel-activity-outline-active); */
	/* border: 1px solid var(--carousel-activity-outline-color-active); */
	/* padding-top:40px; */
	/* padding-bottom:40px; */
}

@media (max-width: 1024px) and (orientation: portrait) {
	.Carousel {
		border-radius: 0px;
		border-left: none;
		border-right: none;
		padding: 0;
		box-shadow: none;
	}
}

@media (max-width: 1024px) and (orientation: landscape) {
	.Carousel {
		/* border-radius: 0px; */
		/* border-left: none; */
		/* border-right: none; */
		padding: 0;
		box-shadow: none;
	}
}

.dragging {
	cursor: grabbing;
	scroll-behavior: auto;
	transition: auto;
}

.dragging-global {
	cursor: grabbing;
	/* cursor: crosshair; */
	/* scroll-behavior: auto;
	transition: auto; */
}

.instant-init {
	transition: auto;
	scroll-behavior: auto;
}

/* rename quick-selector */
.quick-selector {
	/* transition: 250ms; */
	/* transition: 350ms; */
	transition: 400ms;
	transition: 300ms;
	transition: 200ms ease-out;
	transition: 225ms ease-out;
	transition: 250ms ease-out;
	/* transition: 200ms ease-in-out; */
	/* transition: 200ms; */
	/* transition: 250ms; */
	/* transition: 350ms; */
	/* transition: 500ms; */
	scroll-behavior: auto;
}

/* from carousel item  css vvvvv*/

/* img.carousel-img {


    max-width: 60vw;
    max-height: 80vh;
    user-select: none;

}

.img-container {


    user-select: none;
    width: 60vw; */









	.carousel-contents {

	
		flex-direction: row;
		display: flex;
		gap: 24px;

		/* border: red 2px solid; */


		/* transition: none; */
		transition: 500ms;
		/* transition: 600ms ease-out; */
		transition: 500ms ease-out;
		transition: var(--slow-scroll-speed);
		/* transition: 350ms ease-out; */
		/* transition: 350ms ease-out; */
		/* transition: 500ms ease-in-out; */
		/* transition: 600ms ; */
		/* transition: 00ms ease-in-out; */
	/* flex-direction: row; */
	/* display: flex; */

	/* margin: auto; */

	/* background-clip: content-box; */

		/* gap: var(--mat-margin); */


	
	}
	
.carousel-contents-dragging{
	transition: none;
	/* transition: 275ms; */
	/* transition: var(--slow-scroll-speed); */
}
	

.carousel-contents:active *{
	border-radius: 4px/4px;
}


.Carousel{


	/* background-image: url('/src/temp_images/bg_test.svg');  */
	/* background-repeat: repeat; */
	/* background-size: auto;  */
	/* background-size: 5%;  */
	/* background-size: 10%;  */

	
	/* background-color: hsl(0, 0%, 60%); */

	/* background-blend-mode:soft-light; */
}


/* .Carousel:active *{

cursor: grabbing;
} */