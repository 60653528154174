.chiclet-no-role {
	/* width: 262px;
	height: 555px; */
	width: 183.4px;

	height: 388.5px;
	overflow: hidden;
	background-color: white;
	/* background-color: rgb(243, 243, 243); */

	/* box-shadow: inset 0px 0px 0px 2px #d4d4d4, 3px 5px 2px 0px rgba(0, 0, 0, 0.09); */
	/* box-shadow: inset 0px 0px 0px 1.4px #d4d4d4, 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */

	outline: 1px solid #d4d4d4;
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */

	/* border-radius: 9.59px/9.59px; */
	border-radius: 6.713px/6.713px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	user-select: none;
	cursor: pointer;
	/* pointer-events: none; */
	/* zoom: .85; */
	/* zoom: .7; */
	background-size: 100%;
	/* transition: background-size 1000ms ease, translate 100ms; */
	transition: background-size 1000ms ease, translate 100ms, scale 100ms;
	/* transition: background-size 1333ms ease; */
	
	/* transform-origin: 50% 35.5%; */
	
	isolation: isolate;
	/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08)); */
	translate: 0px -5px;

	transition: background-size 500ms, translate 500ms, scale 200ms;
	transition: background-size 600ms, translate 500ms, scale 200ms;
}

.chiclet-no-role:hover {
	/* box-shadow: 3px 5px 2px 0px rgba(0, 0, 0, 0.09);
	border-radius: 9.59px/9.59px; */

	border-radius: 6.713px/6.713px;
	
	/* border-radius: 7px/7px; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 6px solid #C6E3F7; */
	/* outline: 4.2px solid #C6E3F7; */
	
	/* outline: 12px solid #C6E3F7; */
	/* background-size:125%; */
	transition: background-size 500ms ease;
	transition: background-size 333ms ease;
	
	transition: translate 100ms;
	/* transform-origin: 90% 90%; */
	/* translate: 0px -5px; */
	/* filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.09)); */
	/* box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */
	
	transition: background-size 500ms ease, translate 100ms, scale 100ms ease;
	/* translate: 0px -10px; */
	/* filter: drop-shadow(0px 6.5px 4px rgba(0, 0, 0, 0.07)); */
	/* filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.07)); */
	/* filter: drop-shadow(0px 0px 1.4px rgba(0, 0, 0, 0.08)); */
	/* outline: 4.2px solid #C6E3F7; */
	/* outline: 4px solid var(--highlight-color); */
	outline: var(--highlight-stroke) solid var(--highlight-color);


	translate: 0px -10px;
	/* translate: 0px -20px; */
	/* scale: 101.25%; */
	/* scale: 102.5%; */
	/* filter: drop-shadow(0px 3px 3.75px rgba(0, 0, 0, 0.0725)); */

}

.disable-interactions {
	pointer-events: none;
}

.chiclet-no-role-img-div {
	position: relative;
	/* height: 71%; */
	height: 100%;

	display: flex;
	/* background-size: cover; */
	background-position: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-size: inherit;

	/* background: linear-gradient(to bottom, rgb(255, 0, 0), rgba(0, 0, 0, 0)); */
	/* z-index: 1; */
	/* transition: background-size 1000ms ease; */

	/* background-size: 100% */

	/* color: #fff;
    justify-content: center;
	text-align: center;
	font-family:inter;
	font-size: 36px;
	font-style: italic;
	font-weight: 900;
	line-height: normal;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    paint-order: stroke fill; */
	/* transition: background-position 1000ms ease */
}

.chiclet-no-role-img-div img {
	width: 100%;
	height: 100%;
	/* transition: transform 1000ms ease; */
	/* transition: transform 10ms ease; */
	transition: transform 500ms ease;
	/* return speed */
	/* transition: transform 1333ms ease;  */
	transform-origin: 50% 35.5%;
	z-index: -2;
	/* opacity: 10%; */
	position: absolute;
}

/* .image-container::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to bottom, rgba(255, 2, 2, 0.772), rgba(0, 0, 0, 0));
	background-size: cover;
	z-index: 2; 
  } */

.chiclet-no-role-img-div:hover img {
	/* transform: scale(1.25); */
	transform: scale(1.1);
	transform: scale(1.05);
	
	transition: transform 500ms ease;
	transition: transform 350ms ease;
	/* zoom in speed */
	/* transition: transform 333ms ease;
	transition: transform 375ms ease;
	transition: transform 425ms ease;
	transition: transform 450ms ease; */
}

/* .chiclet-no-role-img-div:hover{
	background-position: 50% 29%;
	transition: background-position 500ms ease
	
} */

/* .chiclet-img-div:hover {
    background-size: 125%; 
    background-size: 120%; 

	transition: background-size 0.5s ease;
} */

.chiclet-no-role-title-container {
	/* margin: 0px 10% 0px 10%; */
	margin: 0px 5% 0px 5%;
	/* background: red; */
	position: relative;
	/* z-index: 0; */
}

.chiclet-no-role-text {
	/* color: #fff; */
	-webkit-text-fill-color: white;

	text-align: center;
	font-family: Inter;
	/* font-size: 36px; */
	font-size: 25.2px;
	font-style: italic;
	font-weight: 700;
	line-height: normal;

	z-index: 0;
	/* -webkit-text-fill-color: white;
     -webkit-text-stroke-width: 2px; */
	/* font-family: inter;
	font-size: 36px;
	font-style: italic;
	font-weight: 900;
	line-height: normal;  */
	display: relative;
	/* z-index: 10; */
	/* z-index: 1; */
	/* z-index: 1; */
	/* -webkit-text-fill-color: white; */
	/* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;  */
}

.chiclet-no-role-text::before {
	/* top: 0;
    left: 0; */
	left: 0;
	/* -webkit-text-stroke-width: 5px; */
	-webkit-text-stroke-width: 3.5px;

	-webkit-text-stroke-color: black;
	/* -webkit-text-fill-color: pink; */

	z-index: -1;

	/* color: white; */
	content: attr(text-content);
	/* content: 'hello'; */
	position: absolute;
	/* z-index: 1; */
	/* z-index: -1; */
	/* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black; */
	/* z-index: 0; */
	/* content: attr(data-content);  */
	/* position: absolute;
    top: 0;
    left: 0;
    z-index: -1; 
    background-color: black; 
    color: white; 
    opacity: 0.5;   */
}

/* .chiclet-img {
	width: 100%;
}

.chiclet-bg-img {
	height: 50%;
	background: linear-gradient(316deg, #000 0%, rgba(0, 0, 0, 0) 100%);
} */

/* .chiclet-desc-container {
	height: f;


	
	
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	flex: 1 0 0;
	
	padding-bottom: 9.5%;

} */
/* 
.desc-dates {
	color: #ababab;
	text-align: center;
	font-family: Inter;

	font-size: var(--chiclet-secondary-font);
	font-style: italic;
	font-weight: 400;
	line-height: normal;
} */

/* .desc-role {
	color: #000;
	text-align: center;
	font-family: Inter;

	font-size: var(--chiclet-secondary-font);
	font-style: italic;
	font-weight: 700;
	line-height: normal;



	display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
flex: 1 0 0;

} */

.gradient-style {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		313deg,
		rgba(0, 0, 0, 0.6) 0%,
		rgba(0, 0, 0, 0.08) 59.9%,
		rgba(0, 0, 0, 0) 100%
	);
	z-index: -2;
}

.space-emulator {
	height: 71%;
	width: 100%;
	margin-top: auto;
}



@media (max-width: 1024px) and (orientation: portrait) {
	.chiclet-no-role {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
			scale: 100%;
			filter: none;
	}
	.chiclet-no-role:hover {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
			translate: 0px -5px;
			scale: 100%;
			filter: none;
	}
	.chiclet-no-role:active {
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
			translate: 0px -5px;
			scale: 100%;
			filter: none;
	}
}

@media (max-width: 768px) and (orientation: portrait) {
	.chiclet-no-role {
		/* width: 183.4px; */

		/* height: 388.5px; */
		height: 15.5vh;
		height: 128px;
		/* height: 12vh; */
		/* height: 13vh; */
		/* height: 14vh; */
		overflow: hidden;
		background-color: white;

		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */
			2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09)
			;

		border-radius: 6.713px/6.713px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		user-select: none;
		cursor: pointer;

		background-size: 100%;
		transition: background-size 1000ms ease;

		isolation: isolate;

		width: 85vw;
		/* width: calc(var(--frame-width) - 10vw); */
		width: calc(var(--frame-width) - 48px);



		
	}

	.chiclet-no-role:hover {
		box-shadow: 2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09);
		border-radius: 6.713px/6.713px;

		outline: 4.2px solid #c6e3f7;

		transition: background-size 500ms ease;
		transform-origin: 90% 90%;
	}

	.disable-interactions {
		pointer-events: none;
	}

	.chiclet-no-role-img-div {
		position: relative;
		height: 100%;

		display: flex;
		background-position: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		background-size: inherit;
		/* 
		background-size: cover;
		background-position: center 30%; */
	}

	.chiclet-no-role-img-div img {
		width: 100%;
		/* height: 100%; */
		height: auto;
		transition: transform 1000ms ease;
		/* transform-origin: 50% 35.5%;  */
		z-index: -2;
		/* opacity: 10%; */
		position: absolute;
		/* top: -30%;	 */
		top: -28%;
		/* top: 30%; 
		transform: translateY(-10%); */

		/* top: 30%;  */
		/* top: 0%; 
  transform: translateY(-10%); */
	}

	.chiclet-no-role-img-div:hover img {
		transform: scale(1);
		transition: transform 500ms ease;
	}

	.chiclet-no-role-title-container {
		margin: 0px 5% 0px 5%;
		position: relative;
	}

	.chiclet-no-role-text {
		-webkit-text-fill-color: white;

		text-align: center;
		font-family: Inter;
		/* font-size: 25.2px; */
		font-size: 18.9px;

		font-style: italic;
		font-weight: 700;
		line-height: normal;

		z-index: 0;

		display: relative;
	}

	.chiclet-no-role-text::before {
		/* top: 0;
		left: 0; */
		left: 0;
		/* -webkit-text-stroke-width: 5px; */
		-webkit-text-stroke-width: 3.5px;

		-webkit-text-stroke-color: black;
		/* -webkit-text-fill-color: pink; */

		z-index: -1;

		content: attr(text-content);
		position: absolute;
	}

	.gradient-style {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			313deg,
			rgba(0, 0, 0, 0.6) 0%,
			rgba(0, 0, 0, 0.08) 59.9%,
			rgba(0, 0, 0, 0) 100%
		);
		z-index: -2;
	}

	.space-emulator {
		height: 35%;
		height: 50%;
		height: 45%;
		width: auto;
		margin-top: 0px;
	}


	.chiclet-no-role:hover {
		/* box-shadow: 3px 5px 2px 0px rgba(0, 0, 0, 0.09); */
	
	
		
	
	
		/* border-radius: 9.59px/9.59px; */
		border-radius: 6.713px/6.713px;

		/* outline: var(--highlight-stroke) solid var(--highlight-color); */

		background-size: 100%;

		transition: background-size 500ms ease, translate 100ms, scale 100ms ease;
		transition: background-size 150ms ease, translate 125ms, scale 125ms ease;
		transition: background-size 250ms ease, translate 125ms, scale 125ms ease;
		transition: background-size 125ms ease, translate 125ms, scale 125ms ease;

		/* translate: 0px -10px; */

		scale: 100%;

		/* filter: drop-shadow(0px 3px 3.75px rgba(0, 0, 0, 0.0725)); */


		/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.08)); */
		translate: 0px -5px;

		outline: 1px solid #d4d4d4;
		
		/* box-shadow: inset 0px 0px 0px 1.4px #d4d4d4,
			2.1px 3.5px 1.4px 0px rgba(0, 0, 0, 0.09); */
	
		/* filter: none; */
	
	}


	.chiclet-no-role:active{
		transition: background 0ms, outline 0ms, translate 100ms;
		/* background-color: rgba(0, 0, 0, .01); */
		/* translate: 0px 0px; */
		/* filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.00)); */

		/* filter: drop-shadow(0px 3.5px 1.4px rgba(0, 0, 0, 0.00)); */
		box-shadow: none;
		filter: none;
		translate: 0px -5px;
		translate: 0px -0px;
		
	}

}




.chiclet-container-norole::before{



	/* background-color: hsl(0, 0%, 65%) ; */
	/* background-color: hsl(0, 0%, 80%) ; */

	

}

.chiclet-container-norole:hover::before{

	/* background-color: hsl(0, 0%, 70%) ; */
	/* background-color: hsl(0, 0%, 86%) ; */

}


@media (max-width: 768px) and (orientation: portrait) {
	.chiclet-container *{
		translate: none;
	}
	
	.chiclet-container:hover *{
		translate: none;
	}
	
	
	.chiclet:hover {
	
		outline: var(--highlight-stroke) solid var(--highlight-color);
		outline: 1px solid var(--stroke-color);
	
	
		box-shadow: 
		/* inset 0px 0px 0px 1.4px #d4d4d4, */ 2.1px 3.5px 1.4px 0px
			rgba(0, 0, 0, 0.09);
	
	
			/* box-shadow: none; */
	}
	

	.chiclet-container:active *{
		/* translate: -20px 0px; */

		box-shadow: none;
	}

	.chiclet-container:active {
		/* translate: -20px 0px; */
		translate: 0px 2.5px;
		/* box-shadow: none; */
	}

	}
